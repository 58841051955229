import { Image, Nav, Tab } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import Products from "../../Models/Products";
import OrderData from "../../Models/OrdersData";
import axios from "axios";
import { useEffect, useState } from "react";
import ObiTokenPaymentPage from "./ObiTokenPaymentPage";
import { toast } from "react-hot-toast";
import { useAccount, useContractWrite, useNetwork, useWaitForTransaction } from "wagmi";
import { NetworkConfig, getNetworkConfig } from "../../Utils/OnchainUtils";
import {ethers} from "ethers";

function PricingSidebar({
  onPaymentToggle,
  product,
}: {
  onPaymentToggle: () => void;
  product: Products;
}): JSX.Element {
  enum PaymentMethods {
    Stripe,
    ObiToken,
  }
  const paymentMethodsList = ["stripe", "obitoken", "binance"];
  const [obiPurchaseInProcess, setObiPurchaseInProcess] = useState(false);

  const [activeTab, setActiveTab] = useState<string>("CardPay");
  const [obiTokenPrice, setObitTokenPrice] = useState(0);

  ////// OBI PAYMENTS LOGIC ///////
  const { address, isConnecting, isDisconnected, isConnected } = useAccount()
  const { chain } = useNetwork();
  const navigate = useNavigate();

  const [networkConfig, setNetworkConfig] = useState<NetworkConfig | null>(null);

  useEffect(() => {
    if (typeof chain?.id === "number") {
     
      // if chain.id is not within networkConfig keys
      // it will return null
      const config = getNetworkConfig(chain?.id);
      setNetworkConfig(config);
    } else {
      
      setNetworkConfig(null);
    }
  }, [chain?.id]);
  
  const decimals = 18;

  let OBIAmount = ethers.BigNumber.from(0); // Default to zero

  // Check if the Price and obiTokenPrice are valid and not zero
  if (product?.Price > 0 && obiTokenPrice > 0) {
    try {
      const calculatedAmount = product?.Price / obiTokenPrice;
        
  
      // Check for valid, finite, and positive results
      if (!isNaN(calculatedAmount) && isFinite(calculatedAmount) && calculatedAmount > 0) {
        // Convert to a string with two decimal places
        const formattedAmount = calculatedAmount.toFixed(2);
        // Now safely convert to BigNumber
        OBIAmount = ethers.utils.parseUnits(formattedAmount, decimals);
      } else {
        // Handle invalid or negative results
        throw new Error("Invalid or negative amount calculated");
      }
    } catch (error) {
      console.error("Error in calculating OBIAmount:", error.message);
    }
  } else {
    const errorMessage = "Price or obiTokenPrice is invalid or zero";
    console.error(errorMessage);
  }
  const { data: payInTokenData,  error: payInTokenError,  write: payInToken, isLoading: isTokenTransferLoading, isSuccess: isTokenTransferSucces } = useContractWrite({
    mode: "recklesslyUnprepared",
    address:networkConfig?.paymentContractAddress,
    abi:  networkConfig?.paymentABI,
    functionName: "payInToken",
    args: [networkConfig?.tokenAddress, OBIAmount],
  
  });
  const { isLoading: isTokenTransfer, isSuccess: isTokenTransferSuccess } = useWaitForTransaction({
    hash: payInTokenData?.hash,
    onSuccess(payInTokenData) {
      toast.success("Tokens transferred successfully!");
      // The Payment has been done but we need to notify the backend of it to award the product
      localStorage.setItem("ObiTransactionCompleted", "Completed");
      const config = { headers: { "Authorization": "Bearer " + localStorage.getItem("oauthToken") } };
      axios.get(import.meta.env.VITE_SERVER_ADDRESS + "completeObiTransaction", config)
        .then((resp) => {
          // The transaction is ready on the backend to be completed so we don't need the status data anymore
          localStorage.removeItem("PendingObiTransaction");
          localStorage.removeItem("ObiTransactionCompleted");
          navigate("/Congratulation");
        })
        .catch((error) => {
          console.error("Error notifying backend of transaction completion:", error.message);
          toast.error("Failed to notify backend of transaction completion: " + error.message);
          navigate("/");
        });
    },
    onError(error) {
      console.error("Transaction error:", error.message);
      toast.error("Transaction failed: " + error.message);
    }
  });
  
  
  const { data: approveData, error: approveError, write: approveTokens, isLoading: isApproveLoading, isSuccess: isApproveSuccess } = useContractWrite({
    mode: "recklesslyUnprepared",
    address: networkConfig?.tokenAddress,
    abi: networkConfig?.tokenABI,
    functionName: "approve",
    args: [networkConfig?.paymentContractAddress, OBIAmount],
  
  });
  
  const { isLoading, isSuccess } = useWaitForTransaction(
    {
      hash: approveData?.hash,
  
      onSuccess(approveData)
      {
        localStorage.setItem("PendingObiTransaction", approveData?.hash);
        payInToken();
        toast.success("Tokens Approved successfully!");
      },
  
      onError()
      {
        // Clean up in the backend
      },
    });
  





  ////// END OF LOGIC PAYMENY ////////

  function CreateProductOrder(
    provider: string,
    product: Products,
    quantity: number = 1
  ) {
    const dataToSend = new OrderData();
    dataToSend.productId = product.ProductId;
    dataToSend.provider = provider;
    dataToSend.level = product.Level;
    dataToSend.quantity = quantity;
    dataToSend.recurrent = product.Category === "Subscription";

    const config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("oauthToken"),
      },
    };
    axios
      .post(
        import.meta.env.VITE_SERVER_ADDRESS + "payment/" + provider,
        dataToSend,
        config
      )
      .then((resp) => {
        if (provider !== "obitoken") {
          window.location.href = resp.data;
          document.body.classList.remove("showCheckout");
        } else {
          setObiPurchaseInProcess(true);
          approveTokens();
        }
      })
      .catch((error) => {
        console.error({ error });
      });
  }

  useEffect(() => {
    axios(
      "https://api.coingecko.com/api/v3/simple/price?ids=orbofi-ai&vs_currencies=usd"
    )
      .then((resp) => {
        if (
          resp.status === 200 &&
          resp.data &&
          resp.data["orbofi-ai"] &&
          resp.data["orbofi-ai"]["usd"]
        ) {
          setObitTokenPrice(resp.data["orbofi-ai"]["usd"]);
        } else {
          console.error("Unexpected response structure:", resp);
          // toast.error(
          //   "Failed to fetch token price: Unexpected response structure"
          // );
        }
      })
      .catch((error) => {
        console.error("Error fetching token price:", error);
        toast.error(
          "Failed to fetch token price: " +
            (error.response?.data?.message || error.message)
        );
      });
  }, [obiTokenPrice]);


  return (
    <div className="Payment-sidebar">
      <h3 className="Pricing-popup-Title">
        Checkout Process{" "}
        <Link
          onClick={onPaymentToggle}
          to="javascript:void(0)"
          className="PaymentClose"
        >
          <Icon icon="mingcute:close-line" />
        </Link>
      </h3>
      <div className="Payment-method-main">
        <Tab.Container
          id="left-tabs-example"
          defaultActiveKey="CardPay"
          activeKey={activeTab}
          onSelect={(k) => k && setActiveTab(k)}
        >
          <div className="PaymentMethod">
            <h6>Choose Payment Method</h6>
            <Nav variant="pills" className="c-tabs payment-tabs">
              <Nav.Item>
                <Nav.Link eventKey="CardPay">
                  <i>
                    <Icon icon="solar:card-bold" />
                  </i>{" "}
                  Card Pay
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="OBITokens">
                  <i>
                    <Image src="/assets/icons/orbofiToken.svg" />
                  </i>
                  OBI Tokens
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link disabled eventKey="Binance">
                  <i>
                    <Icon icon="simple-icons:binance" />
                  </i>
                  Binance <small>(Coming Soon)</small>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
          <Tab.Content>
            <Tab.Pane eventKey="CardPay">
              <div className="CardPayInfo">
                <CheckoutProductPanel product={product} activeTab={activeTab} />
                <Link
                  to="javascript:void(0)"
                  className="btn-primary btn-Pay"
                  onClick={() => {
                    CreateProductOrder(
                      paymentMethodsList[PaymentMethods.Stripe],
                      product
                    );
                  }}
                >
                  <i>
                    <Icon icon="solar:card-bold" />
                  </i>{" "}
                  Pay Now{" "}
                  <span>
                    <Icon icon="bi:arrow-right" />
                  </span>
                </Link>
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="OBITokens">
              <div className="CardPayInfo">
                {/* {obiPurchaseInProcess && (
                  <ObiTokenPaymentPage obiTokenOrderData={product} />
                )} */}
                <CheckoutProductPanel
                  product={product}
                  activeTab={activeTab}
                  obiTokenPrice={obiTokenPrice}
                />
                <Link
                  to="javascript:void(0)"
                  className="btn-white btn-Pay"
                  onClick={(e) => {
                    if (isLoading || isTokenTransfer || isTokenTransferLoading || isApproveLoading || isDisconnected) {
                      e.preventDefault(); 
                      return; // Optionally provide feedback or logging here
                    }
                    CreateProductOrder(paymentMethodsList[PaymentMethods.ObiToken], product);
                  }}
                
                >
                  <i className="orbofiTokenIcon">
                    <Image src="/images/logo-coin.svg" />
                  </i>{" "}
                  {
                    isDisconnected ? "Please connect your wallet to proceed" :
                      (isConnected && !(chain?.id === 56 || chain?.id === 1) ? "Please switch to BNB or ETH chain" : "OBI Pay")
                  }
               
                  
                  <span>
                    <Icon icon="bi:arrow-right" />
                  </span>
                </Link>
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="Binance">Second tab content</Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </div>
  );
}

export default PricingSidebar;

function CheckoutProductPanel({
  product,
  activeTab,
  obiTokenPrice,
}: {
  product: Products;
  activeTab: string;
  obiTokenPrice?: number;
}): JSX.Element {
  return (
    <>
      <div className="Pricing-popup-info">
        <span>
          <Image src="/assets/images/product-dummy-img.png" />
        </span>
        <h6>
          <small>{product.Category}</small>
          {product.Name}
        </h6>
        <h5>
          {activeTab === "OBITokens"
            ? `${(product.Price / obiTokenPrice).toFixed(2)} OBI`
            : `$${product.Price}`}
        </h5>
      </div>

      {activeTab !== "OBITokens" ? (
        <>
          <div className="CardPayImg">
            <Image src="/assets/images/cards-pay.png" />
          </div>
          <div className="PaymentSubTotal">
            <h6>
              Total <b>${product.Price}</b>
            </h6>
          </div>
        </>
      ) : (
        <div className="PaymentSubTotal">
          <h6>
            Total <b>{(product.Price / obiTokenPrice).toFixed(2)} OBI</b>
          </h6>
        </div>
      )}
    </>
  );
}
