import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./CommonBtn.css";

interface ComponentProps {
    child: React.ReactNode,
    className: string,
    linkName: string,
    onClick: () => void,
    isDisabled: boolean,
}

function PrimaryBtn(props: ComponentProps) {
  return props.isDisabled ? (
    <div className={`btnImgwrap disabled ${props.className}`}>
      <span><Image src="/assets/images/btn-left-after.svg" /></span>
      <Link className="btn-primary" to={props.linkName} onClick={props?.onClick}>{props.child}</Link>
      <span><Image src="/assets/images/btn-right-after.svg" /></span>
    </div>
  ) : (
    <div className={`btnImgwrap ${props.className}`}>
      <span><Image src="/assets/images/btn-left-after.svg" /></span>
      <Link className="btn-primary" to={props.linkName} onClick={props?.onClick}>{props.child}</Link>
      <span><Image src="/assets/images/btn-right-after.svg" /></span>
    </div>
  );
}

export default PrimaryBtn;
