import { Col, Image, Row } from "react-bootstrap";
import { Icon } from "@iconify/react";
import SecondaryBtn from "../../Components/CommonBtn/SecondaryBtn";
import PinkBtn from "../../Components/CommonBtn/PinkBtn";
import "./CollectionsCTABoxs.css";
import { t } from "i18next";
import { useDispatch } from "react-redux";
import { showPopUpAction } from "../../Redux/Slices/loginPopUpSlice";

function CollectionsCTABoxs() {

  const dispatch = useDispatch();
  function OnOpenLoginPopup() {
    dispatch(showPopUpAction(true));
  }
  return (
    <div className="CollectionsCTABoxs">
      <div className="titleText">
        {/* <h2>{t("seasonal_collections")}</h2> */}
        {/* <p>{t("create_your_personalize_ai_companion_that_can_boos")}</p> */}
      </div>
      <Row>
        <Col lg={6}>
          <div className="CollectionsCTABoxsMain text-center">
            <div className="CollectionsCTABoxsimg">
              <Image src="/assets/images/CollectionsCTABoxs-01.png" />
            </div>
            <div className="CollectionsCTABoxsinfo">
              <h6><i><lord-icon src="https://cdn.lordicon.com/ewlwpzum.json" colors="primary:#4c37cc,secondary:#ffffff,tertiary:#fff,quaternary:#ebe6ef" trigger="loop" ></lord-icon></i> {t("Create_your_own")}</h6>
              <p>{t("Create_real_time_private_voice_powered")}</p>
              {localStorage.getItem("oauthToken") ?
                <>
                  <SecondaryBtn child={<>{t("create")} <i><Icon icon="heroicons:sparkles-solid" /></i></>} className={"CollectionsCTABoxsBtn"} linkName={"/createaicompanion"} />
                </>
                :
                <>
                  <SecondaryBtn child={<>{t("create")} <i><Icon icon="heroicons:sparkles-solid" /></i></>} onClick={OnOpenLoginPopup} className={"CollectionsCTABoxsBtn"} linkName={"/"} />


                </>
              }

            </div>
          </div>
        </Col>
        <Col lg={6}>
          <div className="CollectionsCTABoxsMain text-center">
            <div className="CollectionsCTABoxsimg">
              <Image src="/assets/images/CollectionsCTABoxs-02.png" />
            </div>
            <div className="CollectionsCTABoxsinfo">
              <h6><i><lord-icon src="https://cdn.lordicon.com/fksyccei.json" trigger="loop" colors="primary:#ffffff,secondary:#545454" ></lord-icon></i> {t("discover_what_you_want")}</h6>
              <p>{t("create_in_seconds_any_kind_of_2d_game_assets_chara")}</p>
              {localStorage.getItem("oauthToken") ?
                <>
                  <PinkBtn child={<>{t("discover")} <i><Icon icon="heroicons:sparkles-solid" /></i></>} className={"CollectionsCTABoxsPinkBtn"} linkName={"/createaicompanion"} />
                </>
                :
                <>
                  <PinkBtn child={<>{t("discover")} <i><Icon icon="heroicons:sparkles-solid" /></i></>} className={"CollectionsCTABoxsPinkBtn"} linkName={"/"} onclick={OnOpenLoginPopup} />

                </>
              }
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default CollectionsCTABoxs;
