
import HomeBanner from "../../Components/HomeCompo/HomeBanner/HomeBanner";
import "./HomePage.css";
import CompanionShorts from "../../Components/CompanionShorts/CompanionShorts";
import CollectionsCTABoxs from "../../Components/CollectionsCTABoxs/CollectionsCTABoxs";
import Categories from "../../Components/Categories/Categories";
import CategoriesBox from "../CategoriesBox/CategoriesBox";
import GenerateAnything from "../../Components/GenerateAnything/GenerateAnything";
import CategoriesBigBox from "../../Components/CategoriesBigBox/CategoriesBigBox";
import AnimationCloning from "../../Components/Animation-Cloning/AnimationCloning";
import { useEffect, useRef, useState } from "react";
import CompanionsBoxHomeLIst from "./CompanionsBoxHomeLIst";
import { t } from "i18next";
import TinderSwiper from "./HomeSlider";
import CompanionsSlider from "../../Components/CompanionsSlider/CompanionsSlider";
import { Link } from "react-router-dom";
import { GetPremiumChatbots } from "../../Utils/ChatbotUtils";
import CompanionCard from "../../Components/Cards/CompanionCard";
import AllTimeFavBannerHome from "../../Components/AllTimeFavBanner/AllTimeFavBanner";
import InfiniteScroll from "react-infinite-scroll-component";
import { Col, Row } from "react-bootstrap";
import CompanionsBox from "../../Components/CompanionsBox/CompanionsBox";
import { ChatbotData, GetRandomChatbots } from "../../Utils/ChatbotUtils";
import CompanionsSliderFirst from "../../Components/CompanionsSlider/CompanionsSliderFirst";
import TinderSliderFirst from "./HomeSliderFirst";
import { classNames } from "classnames";
import TinderSliderUserChatbots from "./TinderSliderUserChatbots";
import { Icon } from '@iconify/react';

const HomePage = () => {
  const itemsPerPage = 30;
  const itemsPerPageMobile = 10;
  const divRef = useRef(null);
  const animationCloningRef = useRef<HTMLDivElement | null>(null);
  const [isAnimationPlaying, setIsAnimationPlaying] = useState(false);
  const itemsToFetch = window.innerWidth < 768 ? itemsPerPageMobile : itemsPerPage;
  const [chatbots, setChatbots] = useState<ChatbotData[]>([]);
  const [records, setRecords] = useState(itemsToFetch);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [premiumChatbots, setPremiumChatbots] = useState<ChatbotData[]>([]);

  useEffect(() => {
    GetPremiumChatbots().then((chatbots) => { setPremiumChatbots(chatbots); });
    window.onload = () => {
      fetchChatbots(0);
    };
  }, []);


  function fetchChatbots(page: number) {
    const itemsToFetch = window.innerWidth < 768 ? itemsPerPageMobile : itemsPerPage;
    GetRandomChatbots(page, itemsToFetch).then((newChatbots) => {
      const uniqueNewChatbots = newChatbots.filter(
        newChatbot => !chatbots.find(chatbot => chatbot.id === newChatbot.id)
      );
      const chatbotsList = chatbots.concat(uniqueNewChatbots);
      setChatbots(chatbotsList);
      setRecords(prevRecords => prevRecords + newChatbots.length);
      setHasMore(newChatbots.length === itemsToFetch);
    });
  }

  const loadMore = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    fetchChatbots(nextPage);
  };


  useEffect(() => {

    const handleScroll = () => {
      if (animationCloningRef.current) {
        const rect = animationCloningRef.current.getBoundingClientRect();
        const isVisible = rect.top < window.innerHeight && rect.bottom >= 0;
        setIsAnimationPlaying(isVisible);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);



  const scrollTop = () => {
    if (divRef.current) {
      divRef.current.scrollTop = 0;
    }
  };

  useEffect(() => {
    scrollTop();
  }, []);


  
   // Initialize Telegram Web App
   useEffect(() => {
    // Check if `window.Telegram` exists before accessing `WebApp`
    if (window.Telegram && window.Telegram.WebApp) {
      const tg = window.Telegram.WebApp;
      tg.ready();
    } else {
      // Optionally, you can log or handle the case where `window.Telegram` is undefined
      console.error('Telegram WebApp API is not available.');
    }
  }, []);


  return (
    <div ref={divRef} className="MainContent padding-16 d-margin">
      {/* <span className="top-left-bg"> <Image src="/assets/images/top-left-bg.svg" /> </span>
      <span className="top-right-bg"> <Image src="/assets/images/top-right-bg.svg" /> </span>
      <span className="bottom-left-bg"> <Image src="/assets/images/bottom-left-bg.svg" /> </span>
      <span className="bottom-right-bg"> <Image src="/assets/images/bottom-right-bg.svg" /> </span> */}
      <HomeBanner />
      <div className="titleText py-30">
        <h2>Latest official AI companion</h2>
      </div>
      <div className="mt-3 d-flex chatbotmain">
        {premiumChatbots.filter(chatbot => chatbot.id === 1).map((chatbot) => (
          <Link key={chatbot.chatbot_id} className="chatbotmain-danny" to={`/chat/${chatbot.string_id}`}>
            <span className="premioumbtn"><lord-icon src="https://cdn.lordicon.com/affwfswa.json" trigger="loop" stroke="bold" colors="primary:#983d02,secondary:#983d02"></lord-icon></span>
            <CompanionCard chatbotData={chatbot} />
          </Link>
        ))
        }
      </div>

      <div className="py-30">
        <CompanionsSliderFirst isAtTop={true} />
        <div className="tindermobile mt-3">
          <TinderSwiper displayType='first' />
        </div>
      </div>

      {/* TODO: Show New chatbots as featured, and make the icons bigger */}
      <div className="HomeCategories-List py-30">
        <div className="titleText ">
          <h2>{t("more_categories")}</h2>
          <p>Smart AI companions and clones, with voice and personality</p>
        </div>
        <Categories />
      </div>
      <div className="py-30">
        <CompanionsSlider isAtTop={true} />
        <div className="tindermobile mt-3">
          <TinderSwiper displayType='' />
        </div>

      </div>
      <div className="py-30" ref={animationCloningRef}>
        <AnimationCloning isPlaying={isAnimationPlaying} />
      </div>
      <div className="HomeCategories-List py-30">
        <div className="titleText ">
          <h2>{t("discover_ai_clones")}</h2>
        </div>
        <CategoriesBox />
      </div>
      <div className="py-30">
        <CategoriesBigBox isExplorePage={true} />
      </div>
      <div className="py-30">
        <AllTimeFavBannerHome />
      </div>
      <div className="CompanionsBox-List py-30">
        <span className="mobilebox">
          <CompanionsBoxHomeLIst />
        </span>
      </div>
      <div className="py-30">
        <CompanionShorts HeadTitle="latest_creations" HeadSubTitle="aI_agents_and_clones_created_by_people" isAtTop={true} />
        <div className="tindermobile mt-3">
          <TinderSliderUserChatbots displayType='latest' />
        </div>
      </div>
      <div className="py-30 mb-5">
        <CollectionsCTABoxs />
      </div>
      <div className="py-30 d-none">
        <GenerateAnything />
      </div>
      <div classNames="dummy"></div>
      <InfiniteScroll
        dataLength={records}
        className="row"
        next={() => loadMore()}
        hasMore={hasMore}
        loader={
          <div className="AiImageLoader">
            <div>
              <div className="nb-spinner"></div>
            </div>
          </div>
        }
      >
        {chatbots.length > 0 ? (
          chatbots.map((chatbot, index) => (
            <>
              {index !== 0 && index % 30 === 0 &&
                <>
                  <CompanionsSlider isAtTop={true} />
                  <div className="tindermobile mt-3">
                    <TinderSwiper />
                  </div>
                </>
              }
              {index !== 0 && index % 30 === 0 && <div className="py-30 mb-5">
                <AllTimeFavBannerHome />
              </div>}
              <Col lg={2} key={chatbot.id}>
                <CompanionsBox chatbotData={chatbot} />
              </Col>
            </>
          ))
        ) : (
          <div className="AiImageLoader">
          </div>
        )}
      </InfiniteScroll>
    </div>
  );
};

export default HomePage;
