import Slider, { Settings } from "react-slick";
import { Component, useEffect, useRef, useState } from "react";
import { Icon } from "@iconify/react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./CompanionShorts.css";
import CompanionCard from "../Cards/CompanionCard";
import { ChatbotData, GetChatbotList, GetLatestUserCreatedChatbots } from '../../Utils/ChatbotUtils';
import { t } from "i18next";
import { Link, useNavigate } from 'react-router-dom';
import { showPopUpAction } from '../../Redux/Slices/loginPopUpSlice';
import { useDispatch } from 'react-redux';
import LordIcon from '../LordIcon/LordIcon';
import UserChatbot from '../../Models/UserChatbot';

const sliderSettings: Settings = {
  infinite: false,
  // autoplay: true, 
  // margin: 10,
  autoplaySpeed: 2500,
  arrows: false,
  cssEase: "linear",
  slidesToShow: 6,
  slidesToScroll: 6,
  useTransform: true,
  draggable: false,
  responsive: [
    {
      breakpoint: 1430,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 5,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 2.01,
        slidesToScroll: 2.01,
      },
    },
  ],
};
interface ComponentProps {
  HeadTitle: string
  HeadSubTitle: string
  isAtTop: boolean
}
function CompanionShorts({ HeadTitle, HeadSubTitle, isAtTop }: ComponentProps) {
  const sliderRef = useRef<Slider | null>(null);
  const [chatbotList, setChatbotList] = useState<UserChatbot[]>([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();


  const goToPreviousSlide = () => {
    sliderRef?.current?.slickPrev();
  };

  const goToNextSlide = () => {
    sliderRef?.current?.slickNext();
  };

  useEffect(() => {
    if (!chatbotList || chatbotList.length <= 0) {
      GetLatestUserCreatedChatbots().then((resp) => {
        setChatbotList(resp.sort(() => Math.random() - 0.5));
      }).catch(console.error);
    }
  }, []);

  function CreateAiCompanion() {
    if (localStorage.getItem("oauthToken")) {
      navigate("/createaicompanion");
    }
    else {
      dispatch(showPopUpAction(true));
    }
  }

  return (
    <div className="CompanionShortsMain">
      <div className="CompanionShortsTitleWrap TinderDesktopArrow">
        <div className="titleText">
          <h2>{t(HeadTitle)}</h2>
          <p>{t(HeadSubTitle)}</p>
        </div>
        <div className="d-flex justify-content-center gap-2 desktop-slider-arrows">
          <div className="SliderPrevbtn" onClick={goToPreviousSlide}>
            <Icon icon="ep:arrow-left-bold" />
          </div>
          <div className="Slidernextbtn" onClick={goToNextSlide}>
            <Icon icon="ep:arrow-right-bold" />
          </div>
        </div>
      </div>
      {/* <span className="CompanionShortsSlider tinderdesktop">
        <PanoramaSlider />
      </span> */}
      <Slider ref={sliderRef} {...sliderSettings} className="CompanionShortsSlider tinderdesktop">
        {isAtTop ? <div>
          <div onClick={CreateAiCompanion} className="SliderAddCard">
            <LordIcon
              src="https://cdn.lordicon.com/zrkkrrpl.json"
              colors="primary:#53aab0,secondary:#53aab0"
              trigger="loop"
            />
            <h6>Create a voice AI <br /> assistant</h6>

          </div>
        </div> : ""}
        {chatbotList.map((chatbot) => {
          return <CompanionCard key={chatbot.id} chatbotData={chatbot} />;
        })
        }
      </Slider>
    </div>
  );
}

export default CompanionShorts;
