// AnimationCloning.tsx
import React, { useEffect, useRef, useState } from 'react';
import Lottie, { Options as LottieOptions } from 'react-lottie';
import animationData from "./Animation-Cloning-CTA.json";
import PrimaryBtn from '../CommonBtn/PrimaryBtn';
import { Icon } from '@iconify/react';
import './AnimationCloning.css';
import { t } from "i18next";
import { showPopUpAction } from '../../Redux/Slices/loginPopUpSlice';
import { useDispatch } from 'react-redux';

interface AnimationCloningProps {
    isPlaying: boolean;
}

const AnimationCloning: React.FC<AnimationCloningProps> = ({ isPlaying }) => {
    const lottieRef = useRef<Lottie | null>(null);

    useEffect(() => {
        if (lottieRef.current) {
            if (isPlaying) {
                // If isPlaying is true, play the animation
                lottieRef.current.play();
            } else {
                // If isPlaying is false, pause the animation at 50% and reset
                lottieRef.current.pause();
                lottieRef.current.playSegments([0, 50], true);
            }
        }
    }, [isPlaying]);

    const handleLottieComplete = () => {
        // Handle any logic you need when the animation completes
        // For example, you can reset the animation to the beginning
        lottieRef.current?.playSegments([0, 100], true);
    };

    const defaultOptions: LottieOptions = {
        loop: true, // Set loop to true for continuous looping
        autoplay: false, // Autoplay is controlled by isPlaying prop
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        },
    };
    const dispatch = useDispatch();
    function OnOpenLoginPopup() {
        dispatch(showPopUpAction(true));
    }
    return (
        <div className="Animation-Cloaning-main">
            <div className='Animation-Cloaning-main-info'>
                <h4>{t('clone_yourself_your_friends_your_company_and_give')}</h4>
                <p>{t('clone_yourself_or_anyone_and_give_yourselves_digit')} </p>


                {localStorage.getItem("oauthToken") ?
                    <>
                        <PrimaryBtn child={<>{t('clone_and_earn')} <i><Icon icon="heroicons:sparkles-solid" /></i></>} className={'Animation-Cloaning-btn'} linkName={'/createaicompanion'} />
                    </>
                    :
                    <>
                        <PrimaryBtn child={<>{t('clone_and_earn')} <i><Icon icon="heroicons:sparkles-solid" /></i></>} className={'Animation-Cloaning-btn'} linkName={'/'} onClick={OnOpenLoginPopup} />


                    </>
                }
            </div>
            <div className='Animation-Cloaning-main-img'>
                <Lottie
                    options={defaultOptions}
                    isStopped={!isPlaying}
                    eventListeners={[
                        {
                            eventName: 'complete',
                            callback: handleLottieComplete,
                        },
                    ]}
                    lottieRef={lottieRef}
                />
            </div>
        </div>
    );
};

export default AnimationCloning;
