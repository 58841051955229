import React, { useState, useEffect } from "react";
import { Button, Col, Form, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Slider from "rc-slider";
import {
  useContractWrite,
  useWaitForTransaction,
  useAccount,
  useNetwork,
} from "wagmi";
import AIfactoryABI from "../../Contracts/AITokenization/AIOrbFactory.json";
import OmniFactoryABI from "../../Contracts/AITokenization/OmniFactory.json";
import OmniAIOrb from "../../Contracts/AITokenization/OmniAIOrb.json";
import MantaFactoryABI from "../../Contracts/AITokenization/MantaFactory.json";
import {
  optimism_sepolia,
  bsc_mainnet_factory_address,
  skale_chaos_tesnet_factory,
  base_mainnet_factory,
  polygon_factory_address,
  polygon_omniFactory_address,
  bsc_omni_mainnet_factory_address,
  manta_mainnet_factory,
  arbitrum_sepolia,
  injective_inevm,
  core_dao,
  factory_vrf_OP
} from "../../Contracts/AITokenization/address.js";
import { ipfs_uploader_api } from "../../Contracts/ipfs.js";
import { ORBOFI_BASE_IPFS_URL } from "../../Contracts/ipfs.js";
import axios from "axios";
import { ethers } from "ethers";
import { useNavigate } from "react-router-dom";
import "./TokenizeForm.css";
import {
  lz_endpoints,
  addOmniMintData,
  removeDomain,
  MIN_GAS_TO_STORE,
  splitIntoRanges,
  FUNCTION_TYPE_SEND,
  BATCH_SIZE_LIMIT,
  addOmniChainData,
} from "./apiFunctions";
import { useSwitchNetwork } from "wagmi";
import FactoryVRF from "../../Contracts/AITokenization/FactoryVRF.json"
import toast, { Toaster } from "react-hot-toast";


const bnb_chain_state = "56"; //mainnet = 56 //testnet=97
const TokenizeForm = ({ selectedImages, setShowLayerZero }) => {
  const navigate = useNavigate();
  const [tokenURI, setTokenURI] = useState("");
  const [IPFSLoading, setIPFSLoading] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [destID, setDestID] = useState();
  const [srcContractAddress, setSrcContractAddress] = useState();
  const [destContractAddress, setDestContractAddress] = useState();
  const [totalSupplyOmniContracts, setTotalSupplyOmniContracts] = useState({});
  const [isLoadingForLZContracts, setIsLoadingForLZContracts] = useState(false);
  const [roundTrip, setRoundTrip] = useState(false);
  const [transactions, setTransactions] = useState([]);


  const [tokenizeData, setTokenizeData] = useState({
    name: "",
    blockchain: "",
    totalSupply: 0,
    price: "",
    royalty: 0,
    Symbol: "",
    royaltyReciever: null,
    srcChain: "",
    destChain: "",
  });

  const { address } = useAccount();
  const { chain } = useNetwork();

  const {
    chains,
    error,
    isLoading: isLoadingForNetworkSwitching,
    isSuccess: isSuccessForNetworkSwitching,
    switchNetwork,
  } = useSwitchNetwork({
    onSuccess: () => {
      // if the pending tx's have been completed on the dest chain
      // then we start finalizing the transaction on the src chain
      if (roundTrip) {
        handleSetTrustedRemoteSRC(srcContractAddress, destContractAddress);
      } else {
        handleTransactionWithIds("uri", destID, createNFTCollectionDESTChain);
      }
    },
  });

  // ***** CONTRACT CONFIG ***** //




  const contractConfig = {
    address:
      tokenizeData.blockchain === bnb_chain_state
        ? bsc_mainnet_factory_address
        : tokenizeData.blockchain === "137"
        ? polygon_factory_address
        : tokenizeData.blockchain === "manta"
        ? manta_mainnet_factory
        : tokenizeData.blockchain === "skale"
        ? skale_chaos_tesnet_factory
        : tokenizeData.blockchain === "base"
        ? base_mainnet_factory
        : tokenizeData.blockchain === "optimism"
        ? factory_vrf_OP
        : tokenizeData.blockchain === "arbitrum"
        ? arbitrum_sepolia
        : tokenizeData.blockchain === "injective"
        ? injective_inevm
        : tokenizeData.blockchain === "coreDAO"
        ? core_dao
        : null,
    abi:
      tokenizeData.blockchain === "manta" ||
      tokenizeData.blockchain === "skale" ||
      tokenizeData.blockchain === "base" ||
      tokenizeData.blockchain === "arbitrum" ||
      tokenizeData.blockchain === "injective" ||
      tokenizeData.blockchain === "coreDAO"
        ? MantaFactoryABI.abi
        : tokenizeData.blockchain === "optimism"
        ? FactoryVRF.abi
        : AIfactoryABI.abi,
  };

  // console.log("yooooo",tokenizeData.blockchain === "optimism" )
  // manual config that the dest chain will be bnb test
  const contractConfigDEST = {
    address: bsc_omni_mainnet_factory_address,
    abi: OmniFactoryABI.abi,
  };

  // manual config that the src chain will be mumbai
  const contractConfigSRC = {
    address: polygon_omniFactory_address,
    abi: OmniFactoryABI.abi,
  };

  // NFT Contract Config
  const nftContractConfigSRC = {
    address: srcContractAddress,
    abi: OmniAIOrb.abi,
  };

  const nftContractConfigDEST = {
    address: destContractAddress,
    abi: OmniAIOrb.abi,
  };

  // ***** CONTRACT INTERACTION ***** //
  const {
    writeAsync: createNFTCollection,
    data: nftCollectionData,
    error: contractInteractionError,
  } = useContractWrite({
    ...contractConfig,
    functionName: "createOrb",
  });

  const {
    writeAsync: createNFTCollectionSRCChain,
    data: nftCollectionSRCData,
    isLoading: isCreateContractSRC,
  } = useContractWrite({
    ...contractConfigSRC,
    functionName: "createOrb",
  });

  const {
    writeAsync: createNFTCollectionDESTChain,
    data: nftCollectionDESTData,
    isLoading: isCreateContractDEST,
  } = useContractWrite({
    ...contractConfigDEST,
    functionName: "createOrb",
  });

  const {
    writeAsync: setTrustedRemoteSRC,
    data: trustedRemoteSRCData,
    isLoading: isSetTrustedRemoteSRC,
  } = useContractWrite({
    ...nftContractConfigSRC,
    functionName: "setTrustedRemote",
  });

  const {
    writeAsync: setTrustedRemoteDEST,
    data: trustedRemoteDESTData,
    isLoading: isSetTrustedRemoteDEST,
  } = useContractWrite({
    ...nftContractConfigDEST,
    functionName: "setTrustedRemote",
  });

  const {
    writeAsync: setMinDstDESTGas,
    data: setMinDstGasData,
    isLoading: isSetMinDstDEST,
  } = useContractWrite({
    ...nftContractConfigDEST,
    functionName: "setMinDstGas",
    args: [lz_endpoints["Polygon"]["chainID"], FUNCTION_TYPE_SEND, 225000],
  });

  const {
    writeAsync: setMinDstSRCGas,
    data: setMinSrcGasData,
    isLoading: isSetMinDstSRC,
  } = useContractWrite({
    ...nftContractConfigSRC,
    functionName: "setMinDstGas",
    args: [
      lz_endpoints["BNB Smart Chain"]["chainID"],
      FUNCTION_TYPE_SEND,
      225000,
    ],
  });

  const {
    writeAsync: setDstChainIdToBatchLimitSRC,
    data: setDstChainIdToBatchLimitSRCData,
    isLoading: isLoadingsetDstChainIdToBatchLimitSRC,
    isSuccess: isSuccesssetDstChainIdToBatchLimitSRC,
  } = useContractWrite({
    ...nftContractConfigSRC,
    functionName: "setDstChainIdToBatchLimit",
    args: [lz_endpoints["BNB Smart Chain"]["chainID"], BATCH_SIZE_LIMIT],
  });

  const {
    writeAsync: setDstChainIdToBatchLimitDEST,
    data: setDstChainIdToBatchLimitDESTData,
    isLoading: isLoadingsetDstChainIdToBatchLimitDEST,
    isSuccess: isSuccesssetDstChainIdToBatchLimitDEST,
  } = useContractWrite({
    ...nftContractConfigDEST,
    functionName: "setDstChainIdToBatchLimit",
    args: [lz_endpoints["Polygon"]["chainID"], BATCH_SIZE_LIMIT],
  });

  // mumbai testnet
  async function handleSetTrustedRemoteSRC(srccontract, destcontract) {
    const remoteChainID = lz_endpoints["BNB Smart Chain"]["chainID"];
    const trustedRemote = ethers.utils.solidityPack(
      ["address", "address"],
      [destcontract, srccontract]
    );
    let args = [remoteChainID, trustedRemote];

    const tx = await setTrustedRemoteSRC({ recklesslySetUnpreparedArgs: args });
    await tx.wait();
  }

  // bnb testnet
  async function handleSetTrustedRemoteDEST(srccontract, destcontract) {
    const remoteChainID = lz_endpoints["Polygon"]["chainID"];
    const trustedRemote = ethers.utils.solidityPack(
      ["address", "address"],
      [srccontract, destcontract]
    );
    let args = [remoteChainID, trustedRemote];

    const tx = await setTrustedRemoteDEST({
      recklesslySetUnpreparedArgs: args,
    });
    await tx.wait();
  }

  async function handleTransactionWithIds(
    uri,
    ids,
    createNFTCollectionFunction
  ) {
    let args = [
      tokenizeData.name,
      tokenizeData.Symbol,
      uri,
      tokenizeData.price ? ethers.utils.parseEther(tokenizeData.price) : null,
      MIN_GAS_TO_STORE,
      lz_endpoints[chain?.name]["endpoint"],
      ids[0],
      ids[1],
    ];

    const tx = await createNFTCollectionFunction({
      recklesslySetUnpreparedArgs: args,
    });
    await tx.wait();
  }

  async function handleTransaction(uri) {
    try {
      if (tokenizeData.blockchain === "layerZero") {
        setIPFSLoading(false);
        setIsLoadingForLZContracts(true);

        // splitting total supply into halves, 1st half will exist on the source chain
        // 2nd half will exist on the destination chain
        const [srcIDs, destIDs] = splitIntoRanges(tokenizeData.totalSupply);
        setTotalSupplyOmniContracts({
          srcTotalSupply: srcIDs,
          destTotalSupply: destIDs,
        });
        setDestID(destIDs);

        // first execution with srcIDs

        await handleTransactionWithIds(
          uri,
          srcIDs,
          createNFTCollectionSRCChain
        );
      } else {
        let args = [
          tokenizeData.name,
          tokenizeData.Symbol,
          uri,
          tokenizeData.price
            ? ethers.utils.parseEther(tokenizeData.price)
            : null,
          tokenizeData.totalSupply,
          tokenizeData.royalty * 100,
          tokenizeData.royaltyReciever === null
            ? address
            : ethers.utils.getAddress(tokenizeData.royaltyReciever),
        ];
        if (chain.name == "OP Mainnet") {
          console.log("chain is sepolia");
          const entropyAddress = ethers.utils.getAddress(
            "0xdF21D137Aadc95588205586636710ca2890538d5"
          );
          const providerAddress = ethers.utils.getAddress(
            "0x52DeaA1c84233F7bb8C8A45baeDE41091c616506"
          );

          console.log("entropyAddress", entropyAddress);
          args.push(entropyAddress, providerAddress);
        }

       

        console.log("final args", args);

        const tx = await createNFTCollection?.({
          recklesslySetUnpreparedArgs: args,
        });
        await tx.wait();
      }
    } catch (error) {
    
      if (error.error.data.message.includes("ORB_ALREADY_EXISTS")) {
        let errorMessage = "A collection already exists with that name.";
        console.log(errorMessage)
        alert(errorMessage)
      } else {
        // Use the message from the error data if available
        let errorMessage = error.error.data.message;
        alert(errorMessage)
      }
  
      
    }
  }

  /**
   * This function is used to upload the metadata to IPFS
   * and then retrieves the tokenURI and the passes it
   * to the smart contract via the  handleTransaction function.
   */
  const fetchTokenURI = async (
    orbName,
    pricePerUnit,
    totalSupply,
    royalty,
    image_urls
  ) => {
    let data_to_upload_to_ipfs;

    if (tokenizeData.blockchain === "layerZero") {
      data_to_upload_to_ipfs = {
        name: orbName,
        urls: image_urls,
        supply: totalSupply,
        price: pricePerUnit,
        chainId: chain?.id,
      };
    } else {
      data_to_upload_to_ipfs = {
        name: orbName,
        urls: image_urls,
        supply: totalSupply,
        price: pricePerUnit,
        royalty: royalty,
        chainId: chain?.id,
      };
    }

    setIPFSLoading(true);

    try {
      // qn do i have to upload twice for the layer zero case?
      console.log("data_to_upload_to_ipfs", data_to_upload_to_ipfs);
      const response = await axios.post(
        ipfs_uploader_api,
        data_to_upload_to_ipfs
      );

      if (response.status === 200) {
        const uri = removeDomain(await response.data);
        setTokenURI(uri);
        console.log("token uri", data_to_upload_to_ipfs, uri);
        await handleTransaction(uri);
      }
    } catch (error) {
      console.error(error);
      toast.error("an error occured while securing your data")
    } finally {
      setIPFSLoading(false);
    }
  };

  // ************************************************** WAITING TRANSACTIONS ************************************************** //

  const { isLoadingSrc, isSuccessSrc } = useWaitForTransaction({
    hash: nftCollectionSRCData?.hash,

    async onSuccess(nftCollectionSRCData) {
      const response = nftCollectionSRCData ? nftCollectionSRCData.logs[0] : [];
      const contract_address = response.address;
      setSrcContractAddress(contract_address);
      // manually switching networks to bsc tesnet, this should be made dynamic later 🚨
      if (!roundTrip) {
        switchNetwork?.(56);
        setTransactions((prevTransactions) => [
          ...prevTransactions,
          `Successfully deployed to the source chain, contract address: ${contract_address}`,
        ]);

        console.log(
          "Deployed for LZ on src chain the contract contract address",
          contract_address
        );
      } else {
        console.log("already switched networks");
      }
    },
  });

  const { isLoadingDest, isSuccessDest } = useWaitForTransaction({
    hash: nftCollectionDESTData?.hash,

    async onSuccess(nftCollectionDESTData) {
      const response = nftCollectionDESTData
        ? nftCollectionDESTData.logs[0]
        : [];
      const contract_address = response.address;
      setDestContractAddress(contract_address);
      setTransactions((prevTransactions) => [
        ...prevTransactions,
        `Deployed for LZ on src chain the contract contract address: ${contract_address}`,
      ]);
    },
  });

  useEffect(() => {
    if (destContractAddress) {
      handleSetTrustedRemoteDEST(srcContractAddress, destContractAddress);
    }
  }, [destContractAddress]);

  // handles the transaction for setting the trusted remote on the source chain
  const { isLoadingSetTrustedRemoteSRC, isSuccessSetTrustedRemoteSRC } =
    useWaitForTransaction({
      hash: trustedRemoteSRCData?.hash,

      async onSuccess(trustedRemoteSRCData) {
        const response = trustedRemoteSRCData
          ? trustedRemoteSRCData.logs[0]
          : [];
        setTransactions((prevTransactions) => [
          ...prevTransactions,
          `Successfully called setTrustedRemote on source chain`,
        ]);

        await setMinDstSRCGas();
      },
    });

  // handles the transaction for setting the trusted remote on the destination chain
  const { isLoadingSetTrustedRemoteDEST, isSuccessSetTrustedRemoteDEST } =
    useWaitForTransaction({
      hash: trustedRemoteDESTData?.hash,

      async onSuccess(trustedRemoteDESTData) {
        const response = trustedRemoteDESTData
          ? trustedRemoteDESTData.logs[0]
          : [];
        setTransactions((prevTransactions) => [
          ...prevTransactions,
          `Successfully called setTrustedRemote on destination chain`,
        ]);

        // await call another function here
        await setDstChainIdToBatchLimitDEST();
      },
    });

  // handles the transaction for setting the batch limit on the source chain
  const {
    isLoading: isLoadingSetDstChainIdToBatchLimitDEST,
    isSuccessSetDstChainIdToBatchLimitDEST,
  } = useWaitForTransaction({
    hash: setDstChainIdToBatchLimitDESTData?.hash,

    async onSuccess(setDstChainIdToBatchLimitDESTData) {
      const response = setDstChainIdToBatchLimitDESTData
        ? setDstChainIdToBatchLimitDESTData.logs[0]
        : [];
      setTransactions((prevTransactions) => [
        ...prevTransactions,
        `Successfully set batch limit on destination chain`,
      ]);

      await setMinDstDESTGas();
    },
  });

  // handles the transaction for setting the min dst gas on the destination chain
  const { isLoadingSetMinDstGas, isSuccessSetMinDstGas } =
    useWaitForTransaction({
      hash: setMinDstGasData?.hash,

      async onSuccess(minDstGasData) {
        const response = minDstGasData ? minDstGasData.logs[0] : [];
        setRoundTrip(true);
        setTransactions((prevTransactions) => [
          ...prevTransactions,
          `Successfully set minimum destination gas on source chain`,
        ]);

        // switch networks to polygon
        switchNetwork?.(137);
      },
    });

  // handles the transaction for setting the batch limit on the source chain
  const {
    isLoading: isLoadingSetDstChainIdToBatchLimitSRC,
    isSuccessSetDstChainIdToBatchLimitSRC,
  } = useWaitForTransaction({
    hash: setDstChainIdToBatchLimitSRCData?.hash,

    async onSuccess(setDstChainIdToBatchLimitSRCData) {
      const response = setDstChainIdToBatchLimitSRCData
        ? setDstChainIdToBatchLimitSRCData.logs[0]
        : [];
      setTransactions((prevTransactions) => [
        ...prevTransactions,
        `Successfully set batch limit on source chain`,
      ]);
      setIsLoadingForLZContracts(false);

      // removing the ipfs/ part of the uri
      // ipfs/QmdhMYWTzFZD5QQMjgnoUnU7FZQmLUzH4zFGBNrcvHC4tv/ -> QmdhMYWTzFZD5QQMjgnoUnU7FZQmLUzH4zFGBNrcvHC4tv/
      tokenURI.then((value) => {
        const result = value.split("ipfs")[1];

        const dataToStoreForLZ = {
          tokenURI: result,
          srcContractAddress: srcContractAddress,
          destContractAddress: destContractAddress,
          chainID: chain?.id,
          srcTotalSupply: totalSupplyOmniContracts?.srcTotalSupply,
          destTotalSupply: totalSupplyOmniContracts?.destTotalSupply,
        };
        addOmniChainData(dataToStoreForLZ)
          .then((r) => {
            // navigate to the mint page here
            navigate(
              `/assets/${tokenizeData.name}/${chain?.id}/${srcContractAddress}/lZ`
            );
          })
          .catch((error) => {
            console.error(error);
            alert("Error while storing data on the database!");
          });
      });
    },
  });

  // handles the transaction for setting the min src gas on the source chain
  const { isLoading: isLoadingSetMinSrcGas, isSuccessSetMinSrcGas } =
    useWaitForTransaction({
      hash: setMinSrcGasData?.hash,

      async onSuccess(setMinSrcGasData) {
        const response = setMinSrcGasData ? setMinSrcGasData.logs[0] : [];
        setTransactions((prevTransactions) => [
          ...prevTransactions,
          `Successfully set minimum source gas on source chain`,
        ]);

        await setDstChainIdToBatchLimitSRC();
      },
    });

  /**
   * Handles the transaction for creating a new NFT collection.
   * If the transaction is successful, it sends relevant data to an API and navigates to a new page.
   * @return {Object} An object with 'isLoading' and 'isSuccess' to track transaction status.
   */
  const { isLoading, isSuccess } = useWaitForTransaction({
    hash: nftCollectionData?.hash,

    async onSuccess(nftCollectionData) {
      console.log("successss 🔥");
      const response = nftCollectionData ? nftCollectionData.logs[0] : [];
      // console.log("nft collection data2222", nftCollectionData)
      console.log("response on success", response);
      const contract_address = response.address;

      tokenURI.then(async (resolvedTokenURI) => {
        const user_data = {
          address: address,
          contract_address: contract_address,
          tokenID: 0,
          collection_name: tokenizeData.name,
          chainID: chain?.id,
          tokenURI: `${ORBOFI_BASE_IPFS_URL}/${resolvedTokenURI}0`,
          islZ: false,
        };

        await addOmniMintData(user_data);
        navigate(
          `/assets/${tokenizeData.name}/${chain?.id}/${contract_address}`
        );
      });
    },
  });

 
  // console.log("nftCollectionData", nftCollectionData)

  // *********************************** FORM CONFIG *********************************** //

  const onHandleChnageInput = (event) => {
    const { name, value } = event.target;
    setTokenizeData({ ...tokenizeData, [name]: value });
  };

  const onHandleChnageBlockchain = (value) => {
    setTokenizeData({ ...tokenizeData, blockchain: value });
  };

  const onHandleChangeSrcChain = (value) => {
    setTokenizeData({ ...tokenizeData, srcChain: value });
  };

  const onHandleChangeDestChain = (value) => {
    setTokenizeData({ ...tokenizeData, destChain: value });
  };

  const onHandleChnageRoyalty = (value) => {
    setTokenizeData({ ...tokenizeData, royalty: value });
  };

  const onHandleChangeRoyaltyReciever = (value) => {
    setTokenizeData({ ...tokenizeData, royaltyReciever: value });
  };

  const onHandleChnageTotalSupply = (value) => {
    setTokenizeData({ ...tokenizeData, totalSupply: value });
  };

  const onHandleSubmitForm = async (e) => {
    e.preventDefault();
    // onHandleSubmit(tokenizeData)

    if (selectedImages.length > 1) {
      if (tokenizeData.totalSupply === selectedImages.length || selectedImages.length === 1) {
          console.log("Condition met, proceed with the operation")
          await fetchTokenURI(
            tokenizeData.name,
            tokenizeData.price,
            tokenizeData.totalSupply,
            tokenizeData.royalty,
            selectedImages,
            setIPFSLoading,
            setTokenURI,
            chain,
            createNFTCollection,
            address,
            tokenizeData
          );
      } else {
          toast.error('Total supply should be equal to the number of selected images.');
      }
  } 



  
  };

  return (
    <>
    <Toaster />
      <Row>
        <Col lg={6}>
          <div className="TokenizeInfo CreateCommunityInfo">
            <Link to="/" className="TokenizeInfoLogo">
              <Image src="/images/logo.png" />
            </Link>
            <h2>Why tokenize your AI-generated content? </h2>
            <hr />

            <ul className="tokenizeslider">
              <li className="mb-3">
                <lord-icon
                  trigger="loop"
                  src="/images/icons/arrow02.json"
                  colors="primary:#fff"
                ></lord-icon>
                <div>
                  <h6 className="mb-0">Forever yours</h6>
                  <small>
                    Convert your image into a Web3 virtual good that you own,
                    transfer, or Sell
                  </small>
                </div>
              </li>
              <li className="mb-3">
                <lord-icon
                  trigger="loop"
                  src="/images/icons/arrow02.json"
                  colors="primary:#fff"
                ></lord-icon>
                <div>
                  <h6 className="mb-0">Sell for Crypto</h6>
                  <small>
                    Monetize your virtual good and your prompt. Note that once
                    your image istokenized, the prompt will be hidden to the
                    public
                  </small>
                </div>
              </li>
              <li className="mb-3">
                <lord-icon
                  trigger="loop"
                  src="/images/icons/arrow02.json"
                  colors="primary:#fff"
                ></lord-icon>
                <div>
                  <h6 className="mb-0">Earn </h6>
                  <small>
                    Earn lifetime royalties from this virtual good in the
                    secondary market
                  </small>
                </div>
              </li>
            </ul>
            <br />
            <h2>What is needed? </h2>
            <hr />
            <ul className="tokenizeslider">
              <li className="mb-3">
                <lord-icon
                  trigger="loop"
                  src="/images/icons/arrow02.json"
                  colors="primary:#fff"
                ></lord-icon>
                <div>
                  <h6>
                    Connect your wallet to the blockchain network you want to
                    tokenize your image in( BNB chain or Polygon){" "}
                  </h6>
                </div>
              </li>
              <li className="mb-3">
                <lord-icon
                  trigger="loop"
                  src="/images/icons/arrow02.json"
                  colors="primary:#fff"
                ></lord-icon>
                <div>
                  <h6>
                    Have enough BNB or MATIC in your wallet to pay for network
                    fees that can be less than 2$
                  </h6>
                </div>
              </li>
              <li className="mb-3">
                <lord-icon
                  trigger="loop"
                  src="/images/icons/arrow02.json"
                  colors="primary:#fff"
                ></lord-icon>
                <div>
                  <h6>
                    With Layer Zero, you can deploy your collection on two
                    distinct chains, allowing for seamless cross-chain transfers
                    of items. To facilitate these transfers, it's essential to
                    sign three transactions on the contracts of both the
                    originating and destination chains. Ensure you handle
                    transaction signatures with care to successfully navigate
                    this dual-chain environment.
                  </h6>
                </div>
              </li>
            </ul>
          </div>
        </Col>
        <Col lg={6}>
          {isLoading ||
          IPFSLoading ||
          isCreateContractSRC ||
          isCreateContractDEST ||
          isSetMinDstDEST ||
          isSetMinDstSRC ||
          isSetTrustedRemoteDEST ||
          isSetTrustedRemoteSRC ||
          isLoadingForLZContracts ? (
            <div className="formGroup IPFSInfo mt-4">
              <lord-icon
                trigger="loop"
                src="/images/icons/ipfs.json"
                colors="primary:#36e899,secondary:#ffffff"
              ></lord-icon>

              <h3 className="transaction-title">
                {IPFSLoading
                  ? "Securing your content on IPFS"
                  : isLoading
                  ? "Securing Your Content on the Blockchain"
                  : isLoadingForLZContracts
                  ? "Creating Contracts and Configuring them on the Blockchain"
                  : "waiting for approval"}
              </h3>
              <ul className="transaction-info">
                {transactions.map((transaction, index) => (
                  <li>{transaction}</li>
                ))}
              </ul>
            </div>
          ) : (
            <Form
              className="TokenizeForm wizardFormMain "
              onSubmit={onHandleSubmitForm}
            >
              <h3>Tokenize this asset </h3>
              <div className="formGroup mt-4 c-form-control">
                <label>Name </label>
                <input
                  placeholder="How do you want to name your  virtual good"
                  type="text"
                  name="name"
                  onChange={onHandleChnageInput}
                  required
                  className="form-control"
                />
              </div>
              <div className="formGroup mt-0 c-form-control">
                <label>Symbol/ticker</label>
                <input
                  placeholder="Write a symbol for your virtual good"
                  type="text"
                  name="Symbol"
                  onChange={onHandleChnageInput}
                  className="form-control"
                />
              </div>
              <div className="formGroup mb-0">
                <label>
                  What Blockchain do you want your virtual good to be living in
                </label>
                <ul className="input-wrap">
                  <li>
                    <label htmlFor="BNBChain" className="custom-input">
                      <input
                        type="radio"
                        name="ChainPloygon"
                        id="BNBChain"
                        onChange={() =>
                          onHandleChnageBlockchain(bnb_chain_state)
                        }
                        required
                      />
                      <div>
                        <span>
                          <Image src="/images/icons/BNBChain.png" />
                        </span>
                        <div>
                          <h6>BNB </h6>
                        </div>
                      </div>
                    </label>
                  </li>
                  <li>
                    <label htmlFor="Polygon" className="custom-input">
                      <input
                        type="radio"
                        name="ChainPloygon"
                        id="Polygon"
                        onChange={() => onHandleChnageBlockchain("137")}
                      />
                      <div>
                        <span>
                          <Image src="/images/icons/polygon-main.png" />
                        </span>
                        <div>
                          <h6>Polygon</h6>
                        </div>
                      </div>
                    </label>
                  </li>
                  <li>
                    <label htmlFor="Base" className="custom-input ">
                      <input
                        type="radio"
                        name="ChainPloygon"
                        id="Base"
                        onChange={() => onHandleChnageBlockchain("base")}
                      />
                      <div>
                        <span>
                          <Image src="/images/icons/base-chain.png" />
                        </span>
                        <div>
                          <h6>Base</h6>
                        </div>
                      </div>
                    </label>
                  </li>

                  <li>
                    <label htmlFor="injective" className="custom-input">
                      <input
                        type="radio"
                        name="ChainPloygon"
                        id="injective"
                        onChange={() => onHandleChnageBlockchain("injective")}
                      />
                      <div>
                        <span>
                          <Image src="/images/icons/injective.png" alt="" />
                        </span>
                        <div>
                          <h6>Injective</h6>
                        </div>
                      </div>
                    </label>
                  </li>
                  <li>
                    <label htmlFor="manta" className="custom-input">
                      <input
                        type="radio"
                        name="ChainPloygon"
                        id="manta"
                        onChange={() => onHandleChnageBlockchain("manta")}
                      />
                      <div>
                        <span>
                          <Image src="/images/icons/manta.png" alt="" />
                        </span>
                        <div>
                          <h6>Manta</h6>
                        </div>
                      </div>
                    </label>
                  </li>
                  <li>
                    <label htmlFor="layerzero" className="custom-input">
                      <input
                        type="radio"
                        name="ChainPloygon"
                        id="layerzero"
                        onChange={() => onHandleChnageBlockchain("layerZero")}
                      />
                      <div>
                        <span>
                          <Image src="/images/icons/layerzero.png" alt="" />
                        </span>
                        <div>
                          <h6>Layerzero</h6>
                        </div>
                      </div>
                    </label>
                  </li>
                  <li>
                    <label htmlFor="coreDAO" className="custom-input">
                      <input
                        type="radio"
                        name="ChainPloygon"
                        id="coreDAO"
                        onChange={() => onHandleChnageBlockchain("coreDAO")}
                      />
                      <div>
                        <span>
                          <Image src="/images/icons/core.png" alt="" />
                        </span>
                        <div>
                          <h6>CoreDAO</h6>
                        </div>
                      </div>
                    </label>
                  </li>
                  <li>
                    <label htmlFor="optimism" className="custom-input">
                      <input
                        type="radio"
                        name="ChainPloygon"
                        id="optimism"
                        onChange={() => onHandleChnageBlockchain("optimism")}
                      />
                      <div>
                        <span>
                          <Image src="/images/icons/optimism.png" alt="" />
                        </span>
                        <div>
                          <h6>Optimism</h6>
                        </div>
                      </div>
                    </label>
                  </li>
                  {/* <li>
                    <label htmlFor="arbitrum" className="custom-input">
                      <input type="radio" name="ChainPloygon" id="arbitrum" onChange={() => onHandleChnageBlockchain('arbitrum')}  />
                      <div>
                        <span>
                          <Image src="/images/icons/arbitrum.png" alt="" />
                        </span>
                        <div>
                          <h6>Arbitrum</h6>
                        </div>
                      </div>
                    </label>
                  </li> */}
                  {/* <li>
                    <label htmlFor="skale" className="custom-input">
                      <input type="radio" name="ChainPloygon" id="skale" onChange={() => onHandleChnageBlockchain('skale')}  />
                      <div>
                        <span>
                          <Image src="/images/icons/skale.png" alt="" />
                        </span>
                        <div>
                          <h6>Skale</h6>
                        </div>
                      </div>
                    </label>
                  </li> */}
                </ul>
              </div>
              {tokenizeData.blockchain === "layerZero" && (
                <div className="formGroup mt-1">
                  <ul className="input-wrap">
                    <li>
                      <label htmlFor="layerzero" className="custom-input">
                        <input
                          type="checkbox"
                          name="srcChain"
                          id="srcChain"
                          checked
                          onChange={() => onHandleChangeSrcChain("mumbai")}
                        />
                        <div>
                          <span>
                            <Image src="/images/icons/polygon-main.png" />
                          </span>
                          <div>
                            <h6>
                              Polygon srcChain<small>LZ</small>
                            </h6>
                          </div>
                        </div>
                      </label>
                    </li>
                    <li>
                      <label htmlFor="layerzero" className="custom-input">
                        <input
                          type="checkbox"
                          name="dstChain"
                          id="dstChain"
                          checked
                          onChange={() => onHandleChangeDestChain("bnbtest")}
                        />
                        <div>
                          <span>
                            <Image src="/images/icons/BNBChain.png" />
                          </span>
                          <div>
                            <h6>
                              BNB dstChain<small>LZ</small>
                            </h6>
                          </div>
                        </div>
                      </label>
                    </li>
                  </ul>
                </div>
              )}
              {tokenizeData.blockchain === "other" && (
                <div className="formGroup mt-1">
                  <ul className="input-wrap">
                    <li>
                      <label htmlFor="layerzero" className="custom-input">
                        <input
                          type="checkbox"
                          name="srcChain"
                          id="srcChain"
                          checked
                          onChange={() => onHandleChangeSrcChain("mumbai")}
                        />
                        <div>
                          <span>
                            <Image src="/images/icons/polygon-main.png" />
                          </span>
                          <div>
                            <h6>
                              Polygon srcChain<small>LZ</small>
                            </h6>
                          </div>
                        </div>
                      </label>
                    </li>
                    <li>
                      <label htmlFor="layerzero" className="custom-input">
                        <input
                          type="checkbox"
                          name="dstChain"
                          id="dstChain"
                          checked
                          onChange={() => onHandleChangeDestChain("bnbtest")}
                        />
                        <div>
                          <span>
                            <Image src="/images/icons/BNBChain.svg" />
                          </span>
                          <div>
                            <h6>
                              BNB dstChain<small>LZ</small>
                            </h6>
                          </div>
                        </div>
                      </label>
                    </li>
                  </ul>
                </div>
              )}
              <caption className="">
                your AI-generated asset will always live on the blockchain you
                choose
              </caption>
              <div className="formGroup mb-0 c-form-control">
                <label>Price </label>
                <input
                  placeholder="what is the price you want to be charging for this virtual good"
                  type="number"
                  name="price"
                  min="0"
                  step="any"
                  onChange={onHandleChnageInput}
                  className="form-control"
                />
              </div>
              <caption>
                if you choose BNB chain as the blockchain, the price is
                denominated in BNB, if it is on Polygon, the price is in MATIC.
                You will keep 95% of each sale.
              </caption>
              <div className="formGroup mb-0">
                <label>Total supply </label>
                <div className="sliderMain Setting-Parameters-Slider">
                  <Slider
                    min={10}
                    max={10000}
                    value={tokenizeData.totalSupply}
                    onChange={onHandleChnageTotalSupply}
                    className="Ai-Sidebar"
                  />
                  <div className="sliderMainwrap">
                    <p>1 K</p>
                    <p>5 K</p>
                    <p>10 K</p>
                  </div>
                </div>

                {/* <input type="text" name="totalSupply" placeholder="Amount" className="fromGroup02" value={socialTokenData.totalSupply} onChange={onChangeRange}/> */}
                <input
                  type="number"
                  name="totalSupply"
                  placeholder="Amount"
                  className="fromGroup02 form-control"
                  value={tokenizeData.totalSupply}
                  // max={selectedImages?.length > 1 ? selectedImages.length : 100}
                  onChange={(e) =>
                    onHandleChnageTotalSupply(Number(e.target.value))
                  }
                />
              </div>
              {tokenizeData.blockchain !== "layerZero" && (
                <>
                  <caption>
                    The total supply is the number of copies that you want to
                    issue for the same virtual good/prompt. 1 is for scarcity,
                    10K is a broader inclusivity
                  </caption>
                  <div className="formGroup mb-0">
                    <label>Royalty </label>
                    <div className="sliderMain Setting-Parameters-Slider">
                      <Slider
                        min={1}
                        max={10}
                        value={tokenizeData.royalty}
                        onChange={onHandleChnageRoyalty}
                        className="Ai-Sidebar"
                      />
                      <div className="sliderMainwrap">
                        <p>1 %</p>
                        <p>5%</p>
                        <p>10 %</p>
                      </div>
                    </div>
                    <input
                      type="text"
                      name="totalSupply"
                      placeholder="Amount"
                      className="fromGroup02 form-control"
                      value={tokenizeData.royalty}
                      onChange={(e) =>
                        onHandleChnageRoyalty(Number(e.target.value))
                      }
                    />
                  </div>
                  <caption>
                    Royalty percentage is the lifetime fees that you will be
                    automatically receiving from all the sales of this virtual
                    good on the secondary market. As long as the blockchain is
                    alive
                  </caption>
                  <div className="formGroup mt-0 c-form-control">
                    <label>Royalty Receiver</label>
                    <input
                      placeholder={address}
                      type="text"
                      name="royaltyReciever"
                      onChange={onHandleChnageInput}
                      className="form-control"
                    />
                  </div>
                  <caption>
                    If you prefer not to receive your royalties at the connected
                    address, please add an alternative address. Otherwise,
                    royalties will be sent to the currently connected wallet.
                  </caption>
                </>
              )}

              <div className="text-center mt-3">
                {/* //chain !== chained.bnb_test || isAuthenticated === false  */}

                <Button
                  type="submit"
                  className="btn-primary"
                  variant=""
                  disabled={
                    ![
                      "manta",
                      "skale",
                      "base",
                      "optimism",
                      "arbitrum",
                      chain?.id?.toString(),
                      "layerZero",
                      "coreDAO",
                      "injective",
                    ].includes(tokenizeData?.blockchain)
                  }
                >
                  {IPFSLoading
                    ? "Deploying to IPFS"
                    : isLoading
                    ? "Creating..."
                    : "Tokenize"}
                </Button>
                <caption>
                  It’s free, but you need to pay for Blockchain network fees,
                  aka: gas fees, which can be less than 0.1$
                </caption>
                {isSuccess && (
                  <div>
                    Congrats you have Successfully created your NFT collection
                    <div>
                      <a
                        href={`https://bscscan.com/tx/${nftCollectionData?.hash}`}
                      >
                        check here
                      </a>
                    </div>
                  </div>
                )}
              </div>
            </Form>
          )}
        </Col>
      </Row>
    </>
  );
};

export default TokenizeForm;
