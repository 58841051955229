import React, { useState, useEffect, useRef } from 'react';
import parse from "html-react-parser";

const Typewriter = ({ text, speed, onComplete }) => {
  const [displayedText, setDisplayedText] = useState('');
  const [currentText, setCurrentText] = useState('');
  const textIndex = useRef(0);
  const intervalId = useRef(null);
  const frameCount = useRef(0);

  useEffect(() => {
    const type = () => {
      if (textIndex.current < currentText.length) {
        setDisplayedText((prevDisplayedText) => {
          const nextChar = currentText.charAt(textIndex.current);
          textIndex.current += 1;
  
          if (nextChar === '[' && onComplete) {
            cancelAnimationFrame(intervalId.current);
            onComplete();
          }
  
          return prevDisplayedText + nextChar;
        });
      }
    };
  
    const step = () => {
      type();
      intervalId.current = requestAnimationFrame(step);
    };
  
    intervalId.current = requestAnimationFrame(step);
  
    return () => cancelAnimationFrame(intervalId.current);
  }, [currentText, speed, onComplete]);
  

  useEffect(() => {
    if (text.length > displayedText.length) {
      setCurrentText(text);
    }
  }, [text]);

  return <div className="typewriter">{parse(displayedText)}</div>;
};

export default Typewriter;
