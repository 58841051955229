import { Breadcrumb, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import WizardForm from "react-wizard-form";
import CreateAICompanionStepOne from "./CreateAICompanionStepOne";
import CreateAICompanionStepTwo from "./CreateAICompanionStepTwo";
import CreateAICompanionStepThree from "./CreateAICompanionStepThree";
import "./CreateAICompanionPage.css";
import "./CreateAICompanionNew.css"
import CreateAICompanionStepThreeLite from "./CreateAICompanionStepThreeLite";
import CreateAICompanionStepTwoStep from "./CreateAICompanionStepTwoStep";


function CreateAICompanionPage() {


  return (
    <div className="MainContent page-bg d-margin padding-16 createPageMainContent">
      <span className="top-left-bg"> <Image src="/assets/images/top-left-bg.svg" /> </span>
      <span className="top-right-bg"> <Image src="/assets/images/top-right-bg.svg" /> </span>
      <span className="bottom-left-bg"> <Image src="/assets/images/bottom-left-bg.svg" /> </span>
      <span className="bottom-right-bg"> <Image src="/assets/images/bottom-right-bg.svg" /> </span>
      <div className="">
        <WizardForm>
          <CreateAICompanionStepOne />
          <CreateAICompanionStepTwoStep />
          {/* <CreateAICompanionStepTwo /> */}
          <CreateAICompanionStepThreeLite />
        </WizardForm>
      </div>
    </div>
  );
}

export default CreateAICompanionPage;
