import React, { useEffect, useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// import "swiper/css";
// import "swiper/css/effect-cards";
// import cards from './swiper-bundle.min.js';

import { EffectCards } from "swiper/modules";
import { ChatbotData, GetChatbotList, GetLatestUserCreatedChatbots, GetRandomParentChatbots } from "../../Utils/ChatbotUtils";
import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import UserChatbot from "../../Models/UserChatbot";

interface ComponentProps {
  displayType: string
}

function TinderSlider(props: ComponentProps) {
  const [chatbotList, setChatbotList] = useState<ChatbotData[] | UserChatbot[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isActive, setIsActive] = useState(false);
  // useEffect(() => {
  //   GetChatbotList().then((resp) => {
  //     setChatbotList(resp);
  //   }).catch(console.error);
  // }, []);
  const getRandomNumber = () => {
    // Define the ranges
    const ranges = [
      [2, 7],
      [12, 16],
      [19, 20],
      [24, 24]
    ];

    // Randomly select a range
    const randomRange = ranges[Math.floor(Math.random() * ranges.length)];

    // Generate a random number within the selected range
    return Math.floor(Math.random() * (randomRange[1] - randomRange[0] + 1)) + randomRange[0];
  };

  useEffect(() => {
    if (!chatbotList || chatbotList.length <= 0) {
      const fetchData = async () => {
        try {
          if (props.displayType === 'latest') {
            const resp = await GetLatestUserCreatedChatbots();
            setChatbotList(resp.sort(() => Math.random() - 0.5));
          } else {
            const randomPriority = getRandomNumber();
            const resp = await GetRandomParentChatbots(randomPriority);
            setChatbotList(resp.sort(() => Math.random() - 0.5));
          }
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      };

      fetchData();
    } else {
      setLoading(false);
    }
  }, [props.displayType, chatbotList]);

  const toggleFavouriteChatbot = (e) => {
    e.preventDefault();
    setIsActive(!isActive);
  };

  const [loading, setLoading] = useState(true);
  return (
    <>
      {loading ? (
        <div className="AiImageLoader">
          <div>
            <div className="nb-spinner"></div>
          </div>
        </div>
      ) : (

        <Swiper
          effect={'cards'}
          grabCursor={true}
          modules={[EffectCards]}
          className="mySwiper"

          centeredSlides={true} // Center the slides
          initialSlide={Math.floor(chatbotList.length / 2)}
        >
          {chatbotList.map((chatbot, index) => (
            <SwiperSlide key={chatbot.id}>
              <div className="swiper-slide" key={index}>
                <div className="swipermobilemain">
                  <Link to={`/chat/${chatbot.string_id}`}>
                    <div className="swipermobilemainImg">
                      <Image src={chatbot.img} />
                    </div>
                    <div className="CompanionShorts-Info">
                      <div className="CompanionShorts-Info-Title">
                        <h5> {chatbot.name}</h5>
                        {/* <h5> {chatbot}</h5> */}

                        <Link to="javascript:void(0)" onClick={toggleFavouriteChatbot} className={`FavoriteLink favicon ${isActive ? 'active' : ''}`}> <Icon icon={isActive ? "mingcute:star-fill" : "mingcute:star-line"} /></Link>
                        <Link to="javascript:void(0)"><Icon icon="ep:top-right" /></Link>
                      </div>
                      {/* <p>{chatbot.description}</p> */}
                      <p>{chatbot.description.substring(0, 200) + (chatbot.description.length > 200 ? "..." : "")}</p>
                      {/* <div className="CompanionShorts-UserProfile">
                      <i><Image src={"public/orbofi.png"} /></i>
                      <Link to={`/user/${chatbot.name}`}><h6>{chatbot.name}</h6></Link>
                    </div> */}

                      {/* <ul className="CompanionShorts-Wraps">
                      <li className="FCompanions-layer"><i><Icon icon="bx:layer" /></i> 95<small>/100 </small></li>
                      <li className="FCompanions-users"><i><Icon icon="lucide:users" /></i>0</li>
                      <li className="FCompanions-fire"><i><Icon icon="ant-design:fire-outlined" /></i>0</li>
                    </ul> */}
                    </div>
                  </Link>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </>
  );

}

export default TinderSlider;

