import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./CommonBtn.css";

interface ComponentProps {
    child: React.ReactNode,
    className: string,
    linkName: string,
}

function SecondaryBtn(props: ComponentProps) {
  return (
    <div className={`btnImgwrap ${props.className}`}>
      <span><Image src="/assets/images/SecondaryBtn-after.svg" /></span>
      <Link className="btn-secondary" to={props.linkName}>{props.child}</Link>
      <span><Image src="/assets/images/SecondaryBtn-before.svg" /></span>
    </div>
  );
}

export default SecondaryBtn;
