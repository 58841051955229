import { Nav, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import ChatbotMainItem from "./ChatbotMainItem";
import { t } from "i18next";
import { ChatbotData } from '../../Utils/ChatbotUtils';

function ChatbotsList({ chatbots, loadMore, records, hasMore }: { chatbots: ChatbotData[], loadMore: () => void, records: number, hasMore: boolean }) {
  return (
    <>
      <div className="ChatbotsListHeader mt-lg-4 mt-3 trandingmain">
        {/* <div className="sidebarSearch ChatbotsListSearch">
          <Link to="/"><Icon icon="iconamoon:search" /></Link>
          <input type="text" placeholder={t("search")} />
        </div> */}
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
          {/* <Nav variant="pills" className="c-tabs chatbotList-tabs">
            <Nav.Item>
              <Nav.Link eventKey="first">{t("featured_chatbot")}</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="second">{t("new_chatbot")}</Nav.Link>
            </Nav.Item>
            <Link to="javascript:void(0)" className="ChatbotFilter"><Icon icon="cil:filter" /></Link>
          </Nav> */}
          <Tab.Content className="mt-3">
            <Tab.Pane eventKey="first">
              <ChatbotMainItem chatbots={chatbots} loadMore={loadMore} records={records} hasMore={hasMore} />
            </Tab.Pane>
            <Tab.Pane eventKey="second">
              <ChatbotMainItem chatbots={chatbots} loadMore={loadMore} records={records} hasMore={hasMore} />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </>

  );
}

export default ChatbotsList;
