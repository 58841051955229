

import { Col, Image } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./CategoryChatbots.css";
import ChatbotsList from "../ChatbotsPage/ChatbotsList";
import { useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import CollectionsCTABoxs from "../../Components/CollectionsCTABoxs/CollectionsCTABoxs";
import AllTimeFavBannerHome from "../../Components/AllTimeFavBanner/AllTimeFavBanner";
import CompanionsSlider from "../../Components/CompanionsSlider/CompanionsSlider";
import CompanionsBox from "../../Components/CompanionsBox/CompanionsBox";
import { useParams } from 'react-router-dom';
import { ChatbotData, GetChatbotsByParent } from "../../Utils/ChatbotUtils";
function CategoryChatbots() {
  const itemsPerPage = 50;

  const location = useLocation();
  const stateData = location.state;
  const [chatbots, setChatbots] = useState<ChatbotData[]>([]);
  const [records, setRecords] = useState(itemsPerPage);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const queryParameters = new URLSearchParams(window.location.search);
  const urlSubcatId = queryParameters.get("subcat");


  useEffect(() => {
    fetchChatbots(0);
  }, []);

  function fetchChatbots(page: number) {
    GetChatbotsByParent(urlSubcatId, page, itemsPerPage).then((newChatbots) => {
      const chatbotsList = chatbots.concat(newChatbots);
      setChatbots(chatbotsList);
      setRecords(prevRecords => prevRecords + newChatbots?.length);
      setHasMore(newChatbots?.length === itemsPerPage);
    });
  }

  const loadMore = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    fetchChatbots(nextPage);
  };

  return (
    <>
      <div className="MainContent d-margin padding-16">
        <span className="top-left-bg"> <Image src="/assets/images/top-left-bg.svg" /> </span>
        <span className="top-right-bg"> <Image src="/assets/images/top-right-bg.svg" /> </span>
        <span className="bottom-left-bg"> <Image src="/assets/images/bottom-left-bg.svg" /> </span>
        <span className="bottom-right-bg"> <Image src="/assets/images/bottom-right-bg.svg" /> </span>
        <div className="ChatMainTitleWrap">
          <div className="titleText mb-0">
            <h2>{stateData}</h2>
            {/* <p>{t("create_your_personalize_ai_companion_that_can_boos")}</p> */}
          </div>
          {/* <Link to="/createaicompanion" className="btn-primary">{t("create_ai")} <i><Icon icon="heroicons:sparkles-solid" /></i></Link> */}
        </div>
        {/* <hr className="divider-line" /> */}
        <ChatbotsList chatbots={chatbots} loadMore={loadMore} records={records} hasMore={hasMore} />
        {/* <CategoriesBigSubcategories isExplorePage={true} /> */}
      </div>
    </>
  );
}

export default CategoryChatbots;
