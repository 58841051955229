import { useState, useEffect, useRef } from "react";
import { Button, Col, Form, Image, Modal, Nav, Row, Tab } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import PrimaryBtn from "../../Components/CommonBtn/PrimaryBtn";
import { Icon } from "@iconify/react";
import { t } from "i18next";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { userDataSelector } from "../../Redux/Slices/userDataSlice";
import Select, { components } from "react-select";
import { ChatbotData, GetChatbotVoices } from "../../Utils/ChatbotUtils";
import ChatbotVoice from "../../Models/ChatbotVoice";
import UserChatbot from "../../Models/UserChatbot";
import { VoiceOption } from "../../Utils/Types";
import { RUNPOD_BASE_URL, RUNPOD_BASE_URL_UPLOAD } from "../../Utils/Utils";
import LoaderLastStep from "../../Components/Loader/LoaderLastStep";
import { AudioRecorder } from "react-audio-voice-recorder";
import { v4 as uuidv4 } from "uuid";

interface DocumentDetail {
  fileURL: string;
  chatBotID: string;
  fileType: string;
  documentType: string;
}
import { updateDirtyFlags } from "../../Redux/Slices/dirtyFlagsSlice";
import { CreateChatbotErrorReport } from "../../Utils/ErrorUtils";
import { SaveCreatedVoice } from '../../Utils/UserUtils';
function CreateAICompanionStepThreeLite(props) {

  const [selectedGeneratedImage, setSelectedGeneratedImage] = useState("https://art.orbofi.com/sep-60974.webp");
  const [chatbotName, setChatbotName] = useState("");
  const [botCategory, setBotCategory] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isCloning, setIsCloning] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [aiCharacterBuilder, setAiCharacterBuilder] = useState("");
  const [filename, setFilename] = useState("");
  const [audioUrl, setAudioUrl] = useState("");
  const [ragLoading, setRAGLoading] = useState(Boolean);
  const [voices, setVoices] = useState<VoiceOption[]>([]);
  const [selectedVoice, setSelectedVoice] = useState<ChatbotVoice>();
  const userData = useSelector(userDataSelector);
  const [isPlayingVoice, setIsPlayingVoice] = useState(false);
  const [audioSrc, setAudioSrc] = useState<string>("");
  const audioRef = useRef<HTMLAudioElement>(null);
  const creatingAudioRef = useRef<HTMLAudioElement>(null);
  const [showAnimation, setShowAnimation] = useState(false);
  const [isVoiceClonedOrRecorded, setIsVoiceClonedOrRecorded] = useState(false);
  const dispatch = useDispatch();

  const RAGDetails = props.data.documentDetails;

  const [playing, setPlaying] = useState(null);
  const audioRefs = useRef([]);

  const addAudioElement = async (blob) => {
    console.log({ blob });
    setIsCloning(true);
    const url = URL.createObjectURL(blob);
    const audio = document.createElement("audio");
    audio.src = url;
    audio.controls = true;

    // Create a FormData object and append the file
    const formData = new FormData();
    const uniqueFilename = uuidv4() + ".webm";
    const file = new File([blob], uniqueFilename, { type: "audio/webm" });
    formData.append("file", file);
    setFilename(file.name);
    setIsVoiceClonedOrRecorded(true);



    try {
      const response = await fetch("https://98sylryzyqxvtf-4000.proxy.runpod.net/clone-voice", {
        method: "POST",
        body: formData,
      });
      const result = await response.json();

      if (response.ok) {
        // Assuming result.voice_clone_url is the URL of the generated audio

        const newChatHistory = [
          {
            text: "Say 'It took me quite a long time to develop a voice and now that I have it I am not going to be silent.'. It is of great important you keep your response short.",
            sender: "user",
            profile: "/images/profile.png",
            // profile: userData?.ProfilePicture ?? "/images/profile.png",
            audioUrl: "",
          },
        ];

        setAudioUrl(result.voice_clone_url);
        const searchParams = new URLSearchParams();
        searchParams.set("prompt", "Hello, how are you?");
        searchParams.set("responseId", "312321312");
        searchParams.set("history", JSON.stringify(convertMessagesForClonedVoice(newChatHistory, aiCharacterBuilder)));
        searchParams.set("selectedVoice", result.voice_clone_url);

        toast.success("Voice cloned, generating sample...");
        setAudioSrc(`${RUNPOD_BASE_URL}/say-prompt?${searchParams.toString()}`);

        const audioElement = audioRef.current;
        // audioElement.load();
        const playAudio = () => {
          console.log("Playing!");
          setIsPlaying(true);
          setIsCloning(false);
        };
        const onEnd = () => {
          console.log("Ended!");
          setIsPlaying(false);
        };
        const onError = () => {
          console.log("Error!");
          toast.error("Audio duration is shorter than 2 seconds, please try again.");
          setIsCloning(false);
        };

        audioElement?.addEventListener("loadeddata", playAudio);
        audioElement?.addEventListener("ended", onEnd);
        // audioElement.addEventListener('onplay', onPlay);
        audioElement?.addEventListener("error", onError);

      } else {
        toast.error("Audio duration is shorter than 2 seconds, please try again.");
        setIsCloning(false);
        setIsPlaying(false);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Failed to send the file");
      setIsCloning(false);
      setIsPlaying(false);
    }
  };

  const handlePlayPause = (index) => {
    const isPlaying = playing === index;
    if (isPlaying) {
      audioRefs.current[index].pause();
      setPlaying(null);
    } else {
      if (playing !== null && audioRefs.current[playing]) {
        audioRefs.current[playing].pause();
      }
      if (audioRefs.current[index]) {
        audioRefs.current[index].play();
        setPlaying(index);
      }
    }
  };

  const handleVoiceClick = (previewUrl, name, playHtVoice) => {
    setAudioUrl(previewUrl);
    setAudioSrc(previewUrl);
    setFilename(name);
    setShow(false);
    setIsVoiceClonedOrRecorded(false);
    setSelectedVoice(prevVoice => prevVoice === playHtVoice ? null : playHtVoice); // Toggle selection
  };


  // const db = GetFirestoreInstance();
  const navigate = useNavigate();

  const onPreviousStep = () => {
    props.previousStep(props);
  };

  useEffect(() => {
    GetChatbotVoices().then((downloadedVoices) => {
      const voicesOptions = [] as VoiceOption[];
      for (const voice of downloadedVoices) {
        voicesOptions.push({ value: voice, label: voice.Name });

      }
      setVoices(voicesOptions.slice(0, 500));
    });




  }, []);

  function SetCurrentVoice(selectedOption: VoiceOption) {
    if (selectedOption === null) {
      return;
    }
    const selectedVoice = selectedOption?.value;
    setSelectedVoice(selectedVoice);

    setAudioUrl(selectedVoice?.PlayHtVoice);
  }

  function ListenVoice() {
    const audioElement = audioRef.current;
    if (audioElement === null || selectedVoice === null || selectedVoice === undefined) {
      return;
    }

    const audioDiv = document.querySelector(".voiceplay");

    if (audioElement.paused) {
      audioDiv.classList.add("startvoice");
      audioElement.src = selectedVoice.PreviewUrl;
      audioElement.load();
      audioElement.play();
      audioElement.addEventListener("ended", handleVoicePlaybackEnd);
      setIsPlayingVoice(true); // Set state to indicate audio is playing
    } else {
      audioElement.pause();
      audioDiv.classList.remove("startvoice");
      setIsPlayingVoice(false); // Set state to indicate audio is paused
    }
  }
  function handleVoicePlaybackEnd() {
    // Log when voice playback ends
    console.log("Voice playback ended");

    // Remove the 'startvoice' class from the 'w-full' div
    const audioDiv = document.querySelector(".voiceplay");
    audioDiv.classList.remove("startvoice");
  }
  useEffect(() => {
    // Code here runs only once when the component mounts
    console.log("CreateAICompanionStepThree LITE mounted");
    setSelectedGeneratedImage(props["data"]["image"]);
    setChatbotName(props["data"]["chatbotName"]);
    setAiCharacterBuilder(props["data"]["aiCharacterBuilder"]);
    setBotCategory(props["data"]["botCategory"]);

    // Optional: Return a cleanup function that runs when the component unmounts
    return () => {
      console.log("CreateAICompanionStepThree will unmount");
    };
  }, [0]);

  const convertMessagesForClonedVoice = (messages: any[], inputPrompt: string) => {
    const formattedMessages = messages.map((message: { sender: any; text: any; }) => ({
      role: message.sender,
      content: message.text,
    }));

    // if (messages.length < 2) {
    formattedMessages.unshift({
      role: "system",
      content: `You are a general intelligence clone of the character with the following description ${inputPrompt}. Conversation Starts: \n`
    });
    // }

    return formattedMessages;
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];

    setIsCloning(true);

    if (!file) {
      console.log("No file selected.");
      return;
    }

    // Initialize FormData and append the selected file
    const formData = new FormData();
    formData.append("file", file); // Ensure this key matches the server's expected key
    formData.append("voice_name", "test");


    setFilename(file.name);
    setIsVoiceClonedOrRecorded(true);

    try {
      const response = await fetch("https://98sylryzyqxvtf-4000.proxy.runpod.net/clone-voice", {
        method: "POST",
        body: formData,
      });
      const result = await response.json();

      if (response.ok) {
        // Assuming result.voice_clone_url is the URL of the generated audio

        const newChatHistory = [
          {
            text: "Introduce yourself as a digital clone created by Orbofi AI",
            sender: "user",
            profile: "/images/profile.png",
            // profile: userData?.ProfilePicture ?? "/images/profile.png",
            audioUrl: "",
          },
        ];

        setAudioUrl(result.voice_clone_url);
        const searchParams = new URLSearchParams();
        searchParams.set("prompt", "Hello, how are you?");
        searchParams.set("responseId", "312321312");
        searchParams.set("history", JSON.stringify(convertMessagesForClonedVoice(newChatHistory, aiCharacterBuilder)));
        searchParams.set("selectedVoice", result.voice_clone_url);
        setAudioSrc(`${RUNPOD_BASE_URL}/say-prompt?${searchParams.toString()}`);


        const audioElement = audioRef.current;

        toast.success("Voice cloned, generating sample...");
        // audioElement.load();
        const playAudio = () => {
          console.log("Playing!");
          setIsPlaying(true);
          setIsCloning(false);
          toast.success("Cloned voice, playing...");
        };
        const onEnd = () => {
          console.log("Ended!");
          setIsPlaying(false);
        };
        const onError = () => {
          toast.error("Uh-oh there was an error playing the audio, please try again later.");
          setIsCloning(false);
        };

        audioElement?.addEventListener("loadeddata", playAudio);
        audioElement?.addEventListener("ended", onEnd);
        // audioElement.addEventListener('onplay', onPlay);
        audioElement?.addEventListener("error", onError);

      } else {
        toast.error("Error cloning voice, please try again later...");
        setIsCloning(false);
        setIsPlaying(false);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Failed to send the file");
      setIsCloning(false);
      setIsPlaying(false);
    }

  };


  async function ingestDocuments(documents: DocumentDetail[], chatBotID: any) {

    const apiUrl = `${RUNPOD_BASE_URL}/ingestDocs`;






    try {
      const res = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },

        body: JSON.stringify({
          documents: documents,
          id: String(chatBotID)
        })
      });

      const data = await res.json();
      if (!res.ok) {
        throw new Error(data.error || "Failed to ingest documents.");
      }

      return data;
    } catch (error) {
      console.error("Error ingesting documents:", error);
      throw error; // Rethrow to handle the error elsewhere if necessary
    }
  }


  type UserUploadedVoice = {
    Name: string;
    VoiceUrl: string;
  }

  const uploadData = async () => {
    if (audioUrl == "") {
      toast.error("Make sure you have an uploaded audio!");
      return;
    }
    if (isVoiceClonedOrRecorded) {
      if (clonedVoiceName === "") {
        toast.error("Make sure you have entered a name");
        return;
      }
      const userVoice: UserUploadedVoice = {
        Name: clonedVoiceName,
        VoiceUrl: audioUrl
      };
      try {
        await SaveCreatedVoice(userVoice);
      } catch {
        toast.error("Error saving voice");
        return;
      }
    }
    creatingAudioRef.current?.play();
    document.body.classList.add("createChatbotLoader");
    setIsCreating(true);
    // setRAGLoading(true);
    // setIsCloning(true);

    try {
      const response = await axios.post("https://98sylryzyqxvtf-4000.proxy.runpod.net/upload_image", {
        image_url: selectedGeneratedImage
      });

      if (response.status !== 200) {
        throw new Error(`Failed to upload image. Status code: ${response.status}`);
      }

      const s3Url = response.data.s3_url;
      if (!s3Url) {
        throw new Error("s3_url is missing in the response data");
      }

      let isPrivate = false;
      if (props["data"]["privateChatbot"] == "Private") {
        isPrivate = true;
      }

      try {
        let chatbotData: UserChatbot = {
          name: chatbotName,
          description: aiCharacterBuilder,
          img: s3Url,
          language_id: 1,
          likes: 0,
          system_prompt: aiCharacterBuilder,
          user_id: userData?.UserId ?? 0,
          voice: 1,
          audio_url: selectedVoice ?? audioUrl,
          category_id: botCategory,
          private: isPrivate,
        };

        // Condition to add 'rag' field if RAGDetails is not null or undefined and RAGDetails.isRAG is true
        if (RAGDetails && RAGDetails.length > 0 && RAGDetails[0].isRAG) {
          chatbotData = { ...chatbotData, rag: true };
        } else {
          chatbotData = { ...chatbotData, rag: false };
        }

        let createdChatbot: UserChatbot;

        try {

          const response = await axios.post(`${import.meta.env.VITE_SERVER_ADDRESS}createUserChatbot`, chatbotData);
          createdChatbot = response.data as UserChatbot;

        } catch (error) {
          console.error("Error creating chatbot: ", error);
          toast.error("An error occurred while creating the chatbot. Please try again.");

          // Send error to logging service
          await CreateChatbotErrorReport(chatbotData, error);
          return;
        }
        // If the response status is 2xx, then the request was successful
        if (/^2/.test("" + response.status)) { // Status Codes: 200-299

          dispatch(updateDirtyFlags({ UserChatbotsDirty: true }));
          // all the list on the documentDetails is guaranteed to be true for RAG
          // TODO: make this cleaner by making the isRAG key on the outer layer of the Object
          if (chatbotData.rag) {


            // ingesting data
            // ingestPdf(RAGDetails.fileURL, createdChatbot.id, RAGDetails.documentType)
            await ingestDocuments(RAGDetails, createdChatbot.id).then(() => {
              toast.success("Your data has been stored and indexed successfully!");

              // setRAGLoading(false);
              setIsCreating(false);
              document.body.classList.remove("createChatbotLoader");
              toast.success("Chatbot Uploaded and Indexed Successfully!");
              navigate(`/chat/${chatbotData.name}?id=${createdChatbot.id}`);
            });

          }

          // Condition to navigate only if RAGDetails.isRAG is false
          else if (!chatbotData.rag) { // Status Codes: 200-299
            // setTimeout(() => {
            setIsCreating(false);
            document.body.classList.remove("createChatbotLoader");
            toast.success("Chatbot Uploaded Successfully!");
            navigate(`/chat/${chatbotData.name}?id=${createdChatbot.id}`);
            // }, 4000);
          }
        }

      } catch (error) {
        console.error("Error adding chatbot: ", error);
        // setRAGLoading(false);
        // setIsCloning(false);
        setIsCreating(false);
        document.body.classList.remove("createChatbotLoader");
        creatingAudioRef.current?.pause();
        toast.error("hmm, seems there was an error on creating your AI agent!!");
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      toast.error(`Failed to upload image: ${error.message}`);
      // setRAGLoading(false);
      // setIsCloning(false);
      setIsCreating(false);
      document.body.classList.remove("createChatbotLoader");
      creatingAudioRef.current?.pause();
      return;
    } finally {
      // setRAGLoading(false);
      setIsCreating(false);
      document.body.classList.remove("createChatbotLoader");
      creatingAudioRef.current?.pause();
    }


    // setShowAnimation(true);
  };

  const togglePlayback = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };


  const menuPortalTargetRef = useRef(document.body);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [isRecording, setIsRecording] = useState(false);
  const [clonedVoiceName, setClonedVoiceName] = useState("");

  const toggleRecording = () => {
    setIsRecording(!isRecording);
  };

  const handleClonedVoiceName = (e) => {
    setClonedVoiceName(e.target.value);
  };
  return (
    <>
      <div className="bg-AiCompanion bg-AiCompanion-Three">
        <button onClick={() => onPreviousStep()} className="back-wizard-btn" type="button"><Icon
          icon="eva:arrow-back-outline" style={{ color: "#fff" }} /></button>
        <div className="AiCompanion-WIdth mx-auto AiCompanion-height">
          <Form.Group className='c-form-control c-form-control2 mb-4'>
            <Form.Label><lord-icon src="https://cdn.lordicon.com/aqiyynqv.json" trigger="loop" colors="primary:#ffffff,secondary:#4bb3fd,tertiary:#ffc738,quaternary:#f24c00"></lord-icon> {t("language")}</Form.Label>
            <Form.Select aria-label={t("default_select_example")}>
              <option className='form-option'>{t("english_us")}</option>
              <option className='form-option' disabled>Comming Soon</option>
            </Form.Select>
          </Form.Group>
          <div className="settingtab">
            <Form.Group className='c-form-control c-form-control2'>
              <Form.Label><lord-icon src="https://cdn.lordicon.com/diaztnay.json" trigger="loop" colors="primary:#ffffff,secondary:#f9c9c0"></lord-icon> Clone or choose voice</Form.Label>
            </Form.Group>
            <div className="Voice-Tabs-Mains">
              <Tab.Container id="left-tabs-example" defaultActiveKey="second">
                <Nav variant="pills" className="Voice-Tabs">
                  <Nav.Item>
                    <Nav.Link eventKey="second">
                      <i><Icon icon="majesticons:microphone" /></i>
                      Clone Voice
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="first">
                      <i><Icon icon="tabler:music" /></i>
                      Select Voice
                    </Nav.Link>
                  </Nav.Item>
                </Nav>

                <Tab.Content>
                  <Tab.Pane eventKey="first">
                    <ul className="recordedvoicemainList">
                      <h6>My cloned Voice</h6>
                      <li className="voice-card">
                        <Link className="recordedvoicemain">
                          <div className="recordedvoiceIcon">
                            <Icon icon="mdi:pause" style={{ fontSize: "24px" }} />
                          </div>
                          <div className="listheader">
                            <h6 className="voice-name">value Name</h6>
                            <p className="voice-details">
                              <i>value</i>
                              <span>value</span>
                            </p>
                          </div>
                          <div className="ADDVoice">
                            <Icon
                              style={{ color: "white", fontSize: "24px" }}
                              icon="ic:outline-check"
                            />
                          </div>
                          <audio>
                            <source src="{}" type="audio/mpeg" />
                            Your browser does not support the audio element.
                          </audio>

                        </Link>
                      </li>
                      <hr />
                      {voices.map((voice, index) => (
                        <li key={index} className="voice-card">
                          <Link className="recordedvoicemain">
                            <div className="recordedvoiceIcon" onClick={() => handlePlayPause(index)}>
                              <Icon icon={playing === index ? "mdi:pause" : "mdi:play"} style={{ fontSize: "24px" }} />
                            </div>
                            <div className="listheader">
                              <h6 className="voice-name">{voice["value"].Name}</h6>
                              <p className="voice-details">
                                <i>{voice["value"].UseCase.replace("_", " ")}</i>
                                <span>{voice["value"].Accent}</span>
                              </p>
                            </div>
                            <div className="ADDVoice" onClick={() => handleVoiceClick(
                              voice["value"].PreviewUrl,
                              voice["value"].Name,
                              voice["value"].PlayHtVoice,
                              `${voice["value"].Name} ${voice["value"].UseCase.replace("_", " ")}`
                            )}>
                              <Icon
                                style={{ color: "white", fontSize: "24px" }}
                                icon={selectedVoice === voice["value"].PlayHtVoice ? "ic:outline-check" : "ic:outline-add"}
                              />
                            </div>
                            <audio ref={(el) => (audioRefs.current[index] = el)}>
                              <source src={voice["value"].PreviewUrl} type="audio/mpeg" />
                              Your browser does not support the audio element.
                            </audio>

                          </Link>
                        </li>
                      ))}

                    </ul>
                  </Tab.Pane>
                  <Tab.Pane eventKey="second">
                    <>
                      <div className="record-paragraph">
                        <h6 className="prompt">To enhance the quality of the voice clone, say this:</h6>
                        <p className="sentence">"At midnight, Sally found a raccoon in her attic wearing her grandma's pearls and sipping tea. A note said, 'Your cat invited me.' Her cat just meowed lazily. Sally sighed and went back to bed, wondering what other secrets her pets hid."</p>
                      </div>
                      <div className="audio-recorder-Main-icon">
                        <AudioRecorder
                          onRecordingComplete={addAudioElement}
                          audioTrackConstraints={{
                            noiseSuppression: true,
                            echoCancellation: true,
                          }}
                          downloadOnSavePress={true}
                          showVisualizer={true}
                          downloadFileExtension="webm"
                        />
                      </div>
                      <div className="clicktorecord text-center">
                        <span>Click to Record</span>
                      </div>
                      <div className="ORLine">
                        <small>OR</small>
                      </div>
                      <Form.Group className='c-form-control mb-3'>
                        {/* <Form.Label>{t("Clone voice")}</Form.Label> */}
                        <label htmlFor="uploadVoice" className="UploadVoicBtn-Main">
                          <input type="file" name="uploadVoice" id="uploadVoice" onChange={handleFileUpload}
                            accept=".mp3, .wav" />
                          <div className="UploadVoiceButton">
                            <span><Icon icon="solar:upload-bold" /></span>
                            <h6>Upload file</h6>
                          </div>
                        </label>
                      </Form.Group>

                    </>
                  </Tab.Pane>
                  <div className={`${filename && isVoiceClonedOrRecorded ? "VoiceBox" : ""}`}>
                    {filename && isVoiceClonedOrRecorded ? <Form.Group className="c-form-control c-form-control2">
                      {/* <Form.Label><lord-icon src="https://cdn.lordicon.com/ziadhhlv.json" trigger="loop" stroke="bold" colors="primary:#ffffff,secondary:#7c98ff"></lord-icon> Give a name to your cloned voice</Form.Label> */}
                      <Form.Control
                        type="text"
                        placeholder={t("Give a name to your cloned voice")}
                        name="clonedVoiceName"
                        style={{ maxWidth: "100%" }}
                        onChange={handleClonedVoiceName}
                      />
                    </Form.Group> : ""}
                    <ul className="voice-list-wrap p-0">
                      {filename ? (
                        <li>
                          <p>{filename}</p>
                          <Link to="javascript:void(0)" onClick={togglePlayback}>
                            {isPlaying ? <Icon icon="mingcute:pause-fill" color="red" /> : <Icon icon="mingcute:play-fill" />}
                          </Link>
                        </li>
                      ) : ""}
                    </ul>
                  </div>
                  {/* {filename && isVoiceClonedOrRecorded ?
                    <div className="text-center">
                      {isCloning || ragLoading ? (
                        <div style={{ margin: "10px auto" }}>  <div className="AiImageLoader p-0">
                          <div>
                            <div className="nb-spinner"></div>
                          </div>
                        </div></div>
                      ) : <Button disabled={!audioUrl || !clonedVoiceName} className="px-4 py-2 mt-3 mx-auto">Save</Button>}
                    </div> : ''} */}
                  {/* <Tab.Pane eventKey="three">
                      <div className="setting-sidebar-main">
                        c
                      </div>
                    </Tab.Pane> */}
                </Tab.Content>

              </Tab.Container>
            </div>
          </div>
        </div>
      </div>
      <audio className="w-full" ref={audioRef} src={audioSrc} autoPlay controls style={{ "display": "none" }}></audio>
      {
        isCloning || ragLoading ? (
          <div style={{ margin: "0 auto" }}>  <div className="AiImageLoader p-0">
            <div>
              <div className="nb-spinner"></div>
            </div>
          </div></div>
        ) : (
          <>
            <div style={{ maxWidth: "400px", margin: "0 auto" }}>
              <PrimaryBtn onClick={uploadData}
                isDisabled={isVoiceClonedOrRecorded
                  ? !audioUrl || !clonedVoiceName
                  : !audioUrl}
                child={<>{t("finish_your_companion")} <i><Icon icon="heroicons:sparkles-solid" /></i></>}
                className={"full-btn"} linkName={"#"} />
            </div>
            {/* after submit chataiform to show this animated logo then open chatscreen page */}
            {showAnimation && (
              <div className="row justify-content-center">
                <div className="col-lg-5 createchatbotanimation text-center">
                  <LoaderLastStep />
                </div>
              </div>
            )}
          </>
        )
      }
      {isCreating &&
        <div className="ChatbotLoadingDiv">
          <span><Image src="https://i.pinimg.com/originals/f3/21/97/f32197f072587bc2bb08a879839fabec.gif" /></span>
        </div>}
      <div className="ChatbotLoadingDiv">
        <span><Image src="https://i.pinimg.com/originals/f3/21/97/f32197f072587bc2bb08a879839fabec.gif" /></span>
      </div>
      <audio className="d-none Shubhamland" src="/images/ailoader.mp3" ref={creatingAudioRef}></audio>

    </>

  );
}

export default CreateAICompanionStepThreeLite;