import { Image, Nav, Tab } from "react-bootstrap";
import PremiumProductBoxFaq from "../PremiumProductBox/PremiumProductBoxFaq";
import SubscriptionBox from "../SubscriptionBox/SubscriptionBox";
import OrboxPriceBox from "../OrboxPriceBox/OrboxPriceBox";
import PremiumProducteBox from "../PremiumProductBox/PremiumProductBox";
import Products from "../../../../Models/Products";
import { useEffect, useState } from "react";
import axios from "axios";
import { Icon } from "@iconify/react";
interface PricingTabsProps {
    isFaq: boolean,
    onPaymentToggle : () => void,
    setCurrentProduct: (val: Products) => void,
  }

// function PricingTabs({activeKey, setActiveKey ,onPaymentToggle ,subsData ,setCurrentProduct ,orboxProductData ,premiumProductData}:PricingTabsProps) {
function PricingTabs({ isFaq, setCurrentProduct, onPaymentToggle}: PricingTabsProps) {
    
  //const [productsData, setProductsData] = useState<Array<Products>>([]);
  const [subsData, setSubsData] = useState<Array<Products>>([]);
  const [orboxProductData, setOrboxProductData] = useState<Array<Products>>([]);
  const [premiumProductData, setPremiumProductData] = useState<Array<Products>>([]);
  const [obiTokenPrice, setObitTokenPrice] = useState(0);
  const [activeKey, setActiveKey] = useState("subscription"); // default active key

  function SetProducts(products: Products[]) {
    if (products.length > 0) {
      const subscriptionProducts = products.filter(
        (product) => product.Category === "Subscription"
      );

      // Split the features string into an array for each subscription product
      subscriptionProducts.forEach(product => {
        if (product.Description) {
          product.FeaturesArray = product.Description.split("/");
        }
      });

      const orboxProducts = products.filter(
        (product) => product.Category === "Orbox"
      );

      const otherProducts = products.filter(
        (product) =>
          product.Category !== "Subscription" &&
          product.Category !== "Orbox"
      );

      // Split the features string into an array for each other product
      otherProducts.forEach(product => {
        if (product.Description) {
          product.FeaturesArray = product.Description.split("/");
        }
      });

      setSubsData(subscriptionProducts);
      setOrboxProductData(orboxProducts);
      setPremiumProductData(otherProducts);
    }
  }

  useEffect(() => {
    let products: Products[] = [];
    if (localStorage.getItem("oauthToken")) {
      const config = { headers: { "Authorization": "Bearer " + localStorage.getItem("oauthToken"), }, };
      axios.get(import.meta.env.VITE_SERVER_ADDRESS + "getStoreProducts", config).then((resp) => {
        products = resp?.data as Array<Products>;
        SetProducts(products);
      });
    }
    else {
      axios.get(import.meta.env.VITE_SERVER_ADDRESS + "getPublicStoreProducts").then((resp) => {
        products = resp?.data as Array<Products>;
        SetProducts(products);
      });
    }
  }, []);

  useEffect(() => {
    axios("https://api.coingecko.com/api/v3/simple/price?ids=orbofi-ai&vs_currencies=usd").then((resp) => {
      setObitTokenPrice(resp.data["orbofi-ai"]["usd"]);
    })
      .catch(console.error);
  }, []);

  useEffect(() => {
    const urlArgs = new URLSearchParams(window.location.search);
    const navArgs = urlArgs.get("premium");

    if (navArgs) {
      setActiveKey("premium"); // set active key to "premium" if "premium" is present in the URL
    }
  }, []);

  return (
    <>
            <Tab.Container id="left-tabs-example" activeKey={activeKey} onSelect={setActiveKey}>
              <Nav variant="pills" className="c-tabs pricing-tabs">
                <Nav.Item>
                  <Nav.Link eventKey="subscription">
                    <i>
                      <Icon className="orbofiicon" color="#b9f2ff" icon="fluent:premium-12-filled" />
                    </i>
                    Subscription</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="orbox">
                    <i>
                      <Image src="/images/logo-coin.svg" className="orbofiicon" />
                    </i>
                    Orbox</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="premium">
                    <i>                       
                      <Icon className="orbofiicon" color="#ffcc36" icon="material-symbols:workspace-premium-rounded" />
                    </i>
                    Premium Chatbots</Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="subscription"><SubscriptionBox onPaymentToggle={onPaymentToggle} subscriptionProducts={subsData} setProduct={setCurrentProduct} /></Tab.Pane>
                <Tab.Pane eventKey="orbox"><OrboxPriceBox paymentToggle={onPaymentToggle} orboxProducts={orboxProductData} setProduct={setCurrentProduct} /></Tab.Pane>
                <Tab.Pane eventKey="premium">
                  <PremiumProducteBox paymentToggle={onPaymentToggle} premiumProducts={premiumProductData} setProduct={setCurrentProduct} />
                  {isFaq ? <PremiumProductBoxFaq /> : ''}
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
    </>
  );
}

export default PricingTabs;
