import {createSlice} from "@reduxjs/toolkit";


type DirtyFlags = {
    FavouriteChatbotsDirty: boolean;
    UserChatbotsDirty: boolean;
    RecentChatbotsDirty: boolean;
    CurrentChatbotDirty: boolean;

}

const initialStruct: {
  FavouriteChatbotsDirty: boolean;
  UserChatbotsDirty: boolean;
  RecentChatbotsDirty: boolean;
  CurrentChatbotDirty: boolean;
} = {
  FavouriteChatbotsDirty: true,
  UserChatbotsDirty: true,
  RecentChatbotsDirty: true,
  CurrentChatbotDirty: true
};

export const dirtyFlagsSlice = createSlice({
  name: "dirtyFlags",
  initialState: {
    value: initialStruct
  },
  reducers: {
    updateDirtyFlags: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value = action.payload;
    }
  }
});

export const { updateDirtyFlags } = dirtyFlagsSlice.actions;

export default dirtyFlagsSlice.reducer;

export const dirtyFlagsSelector = (state: { dirtyFlags: { value: DirtyFlags; }; })  => state.dirtyFlags.value;