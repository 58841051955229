class OrderData {
  productId: number;
  level: number;
  quantity: number;
  provider: string;
  recurrent: boolean;

  constructor(
    productId: number = -1,
    level: number = 0,
    quantity: number = 0,
    provider: string = "",
    recurrent: boolean = false,
  ) {
    this.productId = productId;
    this.level = level;
    this.quantity = quantity;
    this.provider = provider;
    this.recurrent = recurrent;
  }
}

export default OrderData;
