import React from "react";
import "./CongratulationsPage.css";
import Confetti from "react-confetti";

import TextTransition, { presets } from "react-text-transition";
import "animate.css";
import {useNavigate} from "react-router-dom";
import {SetDirty} from '../../App';
import {CheckTransaction, DownloadData, DownloadSubInfo, HandleObiTransaction} from '../../Utils/UserUtils';
import {useDispatch} from 'react-redux';
import {CheckPendingTransaction} from '../../Utils/Utils';
const colors = ["#ff595e", "#d1d646", "#00798c", "#edae49"];

function CongratulationsPage() {
  const [index, setIndex] = React.useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  React.useEffect(() => {
    localStorage.removeItem("subscription");
    localStorage.removeItem("userData");
    HandleObiTransaction().then(() => {
      CheckPendingTransaction().then(async () => {
        DownloadData(dispatch)
          .then(() => {
            DownloadSubInfo(dispatch).then(()=>{
              if(localStorage.getItem("premiumChatbotReturn"))
              {
                const chatbotName = localStorage.getItem("premiumChatbotReturnName");
                localStorage.removeItem("premiumChatbotReturnName");
                navigate(`/chat/${chatbotName}`);
              }
              else
              {
                navigate("/");
              }
            }).catch(console.error);
          })
          .catch(console.error);
      }).catch(console.error);
    });



    const intervalId = setInterval(
      () => setIndex((index) => index + 1),
      3000 // every 3 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);

  return (
    <>
      <div className="MainContent d-margin page-bg congratsmain">
        <Confetti />
        <TextTransition
          springConfig={presets.wobbly}
          style={{ color: colors[index % colors.length] }}
        />
        <div className="padding-16 h-100" >
          <div className="pricing-main-title congrats">
            <div className="congratulations">
              <div className="checkmark2-circle">
                <div className="background"></div>
                <div className="checkmark2 draw"></div>
              </div>
              <h1>Congratulations!</h1>
              <p>You are all set. Well done!</p>
              <button className="btn-primary" type="submit">Continue</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CongratulationsPage;
