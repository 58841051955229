import { toast } from "react-hot-toast";
import { v4 as uuidv4 } from 'uuid';
import { CodeGenerationParams, generateCode } from '../../Components/AppGenerator/helpers/generateCode';
import { HistoryItem } from '../../Components/AppGenerator/history/history_types';
import { describeImageForAppGen, uploadImageToS3 } from './utils';
import { ChatMessage } from './interface';
import { RUNPOD_BASE_URL } from '../../Utils/Utils';
import { RefObject } from "react";

// Utility function to classify voice and set typing state
export const handleVoiceClassification = (
  isVoiceClassified: boolean,
  setIsTyping: (value: boolean) => void
): boolean => {
  if (!isVoiceClassified) {
    toast.error(
      "Voice not classified, try again in a moment or contact support"
    );
    setIsTyping(false);
    return false;
  }
  return true;
};

// Utility function to handle streaming and refIndex increment
export const handleStreamingAndRefIndex = (
  isStreaming: boolean[],
  refIndex: number,
  hasRan: boolean,
  setIsStreamingForIndex: (index: number, value: boolean) => void
): number => {
  if (isStreaming[refIndex]) {
    isStreaming[refIndex] = false;
    toast.error("Stopping & sending new message");
  }

  if (hasRan) {
    refIndex += 1;
  }
  setIsStreamingForIndex(refIndex, true);

  return refIndex;
};

// Utility function to handle chat history update
export const updateChatHistory = async (
  params: CodeGenerationParams,
  chatHistory: ChatMessage[],
  api_key: string
): Promise<ChatMessage[]> => {
  let newChatHistory;

  if (params.inputType === "image" && params.generationType === "create") {
    try {
      let opinion = await describeImageForAppGen(params, api_key);
      newChatHistory = [
        ...chatHistory,
        {
          text: opinion,
          sender: "user",
          profile: "/images/profile.png",
          audioUrl: "",
        },
      ];
    } catch (error) {
      console.error("Failed to get opinion:", error);
    }
  } else if (
    params.inputType === "text" &&
    params.generationType === "create"
  ) {
    newChatHistory = [
      ...chatHistory,
      {
        text: params.textPrompt,
        sender: "user",
        profile: "/images/profile.png",
        audioUrl: "",
      },
    ];
  } else {
    newChatHistory = [
      ...chatHistory,
      {
        text: params.textPrompt
          ? params.textPrompt
          : params.history[params.history.length - 1],
        sender: "user",
        profile: "/images/profile.png",
        audioUrl: "",
      },
    ];
  }

  return newChatHistory;
};

// Utility function to play audio on iPhone
export const playAudioOnIphone = (
  searchParams: URLSearchParams,
  audioContainerRef: React.RefObject<HTMLDivElement>,
  setTalkingState: any
) => {
  const src = `${RUNPOD_BASE_URL}/say-prompt?${searchParams.toString()}`;

  if (audioContainerRef.current) {
    const audioElement = document.createElement("audio");
    audioElement.className = "w-full";
    audioElement.controls = true;
    audioElement.autoplay = true;

    const sourceElement = document.createElement("source");
    sourceElement.src = src;
    sourceElement.type = "audio/mpeg";

    audioElement.appendChild(sourceElement);
    audioContainerRef.current.appendChild(audioElement);

    audioElement.load();

    audioElement.addEventListener("ended", () => {
      setTalkingState("idle");
      audioElement.pause();
      audioContainerRef.current.innerHTML = "";
    });

    audioElement.addEventListener("loadeddata", () => {
      audioElement.play();
    });

    audioElement.addEventListener("canplay", () => {
      audioElement.play();
    });

    audioElement.addEventListener("progress", () => {
      audioElement.play();
    });

    audioElement.addEventListener("canplaythrough", () => {
      audioElement.play();
    });

    audioElement.addEventListener("loadedmetadata", () => {
      audioElement.play();
    });

    audioElement.addEventListener("play", () => {
      setTalkingState("talking");
      console.log("Audio is playing");
    });
  }
};

// Utility function to play audio on non-iPhone devices
export const playAudioOnNonIphone = async (
  url: string,
  audioRef: React.RefObject<HTMLAudioElement>,
  onErrorInStream:any,
  setTalkingState: any
) => {
  try {
    const mediaSource = new MediaSource();
    const audioElement = audioRef.current;

    audioElement.src = URL.createObjectURL(mediaSource);

    mediaSource.addEventListener("sourceopen", async () => {
      const mimeCodec = "audio/mpeg";
      if (MediaSource.isTypeSupported(mimeCodec)) {
        const sourceBuffer = mediaSource.addSourceBuffer(mimeCodec);

        try {
          const response = await fetch(url);
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }

          const reader = response.body.getReader();

          const processChunk = async ({ done, value }) => {
            if (done) {
              mediaSource.endOfStream();
              return;
            }

            await new Promise((resolve) => {
              if (!sourceBuffer.updating) {
                resolve();
                return;
              }
              const onUpdateEnd = () => {
                sourceBuffer.removeEventListener("updateend", onUpdateEnd);
                resolve();
              };
              sourceBuffer.addEventListener("updateend", onUpdateEnd);
            });

            sourceBuffer.appendBuffer(value);

            reader.read().then(processChunk);
          };

          reader.read().then(processChunk);
        } catch (fetchError) {
          console.error("Fetch error:", fetchError);
          toast.error(`Fetch error: ${fetchError.message}`);
          onErrorInStream(`Fetch error: ${fetchError.message}`);
          return;
          // throw fetchError; // Re-throw to be caught by the parent try-catch
        }
      } else {
        console.error(`MIME type ${mimeCodec} is not supported`);
        toast.error(`MIME type ${mimeCodec} is not supported`);
        throw new Error(`MIME type ${mimeCodec} is not supported`);
      }
    });

    await audioElement.play().then(() => {
      // Show a toast notification when the audio starts playing
      // toast.error("Audio is playing");
      setTalkingState("talking");
      

      // Add an event listener for the 'ended' event to show a toast when the audio ends
      audioElement.addEventListener('ended', () => {
        // toast.error("Audio has finished playing");
        setTalkingState("idle");
      });
    }).catch((playError) => {
      if (playError.name === "AbortError") {
        console.warn("Audio playback was aborted:", playError);
        // Optional: handle AbortError specifically
        onErrorInStream(playError);
        setTalkingState("idle");
      } else {
        console.error("Playback error:", playError);
        toast.error(`Playback error: ${playError.message}`);
        onErrorInStream(playError);
        setTalkingState("idle");
      }
    });
    // console.log("Audio is playing");
  } catch (error) {
    console.error("Playback error:", error);
    toast.error(`Playback error: ${error.message}`);
    throw error; // Re-throw the error to be caught by the parent try-catch
  }
};
// Utility function to handle code generation logic
export const generateCodeLogic = (
  params: CodeGenerationParams,
  parentVersion: number | null,
  settings: any,
  wsRef: React.RefObject<WebSocket>,
  setGeneratedCode: React.Dispatch<React.SetStateAction<string>>,
  setAppHistory: React.Dispatch<React.SetStateAction<HistoryItem[]>>,
  setCurrentVersion: any,
  setExecutionConsole: any,
  setAppState: any,
  setIsTyping: React.Dispatch<React.SetStateAction<boolean>>,
  updateInstruction: string,
  imageFilesWithPreviews: any[],
  AppState: any
) => {
  const updatedParams = { ...params, ...settings };
  setIsTyping(false);

  generateCode(
    wsRef,
    updatedParams,
    (token) => setGeneratedCode((prev) => prev + token),
    (code) => {
      setGeneratedCode(code);

      if (params.generationType === "create") {
        const inputs =
          params.inputType === "image"
            ? { image_url: imageFilesWithPreviews[0].dataUrl }
            : { text: params.textPrompt };
        setAppHistory([
          {
            type: "ai_create",
            parentIndex: null,
            code,
            inputs,
          },
        ]);
        setCurrentVersion(0);
      } else {
        setAppHistory((prev) => {
          if (parentVersion === null) {
            toast.error("Error on Updating your prompt");
            return prev;
          }

          const newHistoryItem: HistoryItem = {
            type: "ai_edit" as const, 
            parentIndex: parentVersion,
            code,
            inputs: {
              prompt: updateInstruction,
            },
          };

          const newHistory = [...prev, newHistoryItem];
          setCurrentVersion(newHistory.length - 1);
          return newHistory;
        });
      }
    },
    (line) => setExecutionConsole((prev) => [...prev, line]),
    () => {
      setAppState(AppState.CODE_READY);
      
    }
  );
};


export const handleImageUpload = async (
  imageForS3Upload: any
): Promise<string> => {
  return await uploadImageToS3(imageForS3Upload);
};



export function prepareAudioSearchParams(uuid, chatHistory, inputPrompt, chatbotData, selectedVoice, isUserOnIphone, selectedModelOption, imageVision = false) {
  const searchParams = new URLSearchParams();
  searchParams.set("prompt", ""); // Adjust accordingly if you need to pass specific prompts
  searchParams.set("responseId", uuid);
  searchParams.set("history", JSON.stringify(chatHistory));
  searchParams.set("selectedVoice", selectedVoice);
  searchParams.set("introMsg", "true");
  searchParams.set("chatId", chatbotData.id);
  searchParams.set("isRAG", chatbotData.rag.toString());
  searchParams.set("imageVision", imageVision.toString()); // If image vision details are needed
  searchParams.set("isIphone", isUserOnIphone.toString());
  searchParams.set("selectedModel", selectedModelOption);
  return searchParams;
}


export function setupAudioPlayback(audioContainerRef, src) {
  if (audioContainerRef.current) {
    let audioElement = audioContainerRef.current.querySelector("audio") || document.createElement("audio");
    if (!audioContainerRef.current.contains(audioElement)) {
      audioContainerRef.current.appendChild(audioElement);
    }
    
    let sourceElement = audioElement.querySelector("source") || document.createElement("source");
    if (!audioElement.contains(sourceElement)) {
      audioElement.appendChild(sourceElement);
    }

    sourceElement.src = src;
    audioElement.load();

    const playAudio = () => {
      audioElement.play().catch(error => console.error("Playback failed:", error));
    };

    // Event listeners
    audioElement.addEventListener("ended", () => {
      audioContainerRef.current.innerHTML = ''; // Clear audio element after playback
    });
    audioElement.addEventListener("loadeddata", playAudio);
    audioElement.addEventListener("canplay", playAudio);
    audioElement.addEventListener("progress", playAudio);
    audioElement.addEventListener("canplaythrough", playAudio);
    audioElement.addEventListener("loadedmetadata", playAudio);
  }
}

export function manageAudioRef(audioRef, src, setLoading, setTalkingState, toast) {
  if (!audioRef.current) return;
  const audioElement = audioRef.current;

  audioElement.src = src;
  const onError = () => {
    setLoading(false);
    setTalkingState("idle");
    console.error("Error loading audio");
    toast.error("Error loading audio, browser not supported");
  };

  // Setting up event listeners
  audioElement.onerror = onError;
  audioElement.onloadeddata = () => audioElement.play();
  audioElement.oncanplay = () => audioElement.play();
  audioElement.onprogress = () => audioElement.play();
  audioElement.oncanplaythrough = () => audioElement.play();
}