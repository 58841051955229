import axios from "axios";
import User from "../Models/User";
import Subscription from "../Models/Subscription";
import OrdersFiat from "../Models/OrdersFiat";
import Invitations from "../Models/invitations";
import { CheckPendingTransaction, GetSubInfo, GetUserData } from "./Utils";
import { ValidateInvitation } from "../Components/PopUps/InviteFriendPopup/InviteFriendPopup";
import { updateUserData } from "../Redux/Slices/userDataSlice";
import { updateSubInfo } from "../Redux/Slices/subscriptionDataSlice";
import { UnknownAction } from "@reduxjs/toolkit";
import { Dispatch } from "react";
import { SetDirty } from "../App";
import { openOnboarding } from "../Redux/Slices/onboardingSlice";
import { NavigateFunction } from "react-router-dom";

type UserData = {
  orders: OrdersFiat[];
  referred: Invitations[];
  subscription: Subscription;
  user: User;
};

export async function DownloadData(dispatch: Dispatch<UnknownAction>) {
  try {
    const resp = await GetUserData();
    dispatch(updateUserData(resp));
  } catch (e) {
    throw e;
  }
}

export async function DownloadSubInfo(dispatch: Dispatch<UnknownAction>) {
  try {
    const resp = await GetSubInfo();
    dispatch(updateSubInfo(resp));
  } catch (error) {
    console.error(error);
  }
}

export async function HandleObiTransaction() {
  // For some reason we got a pending obi transaction maybe due to a server disconnect
  if (localStorage.getItem("PendingObiTransaction")) {
    // If transaction is completed update the backend
    if (localStorage.getItem("ObiTransactionCompleted") === "Completed") {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("oauthToken"),
        },
      };
      axios
        .get(
          import.meta.env.VITE_SERVER_ADDRESS + "completeObiTransaction",
          config,
        )
        .then(() => {
          localStorage.removeItem("ObiTransactionCompleted");
          localStorage.removeItem("PendingObiTransaction");
          window.location.href = document.title + "?transaction";
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }
}

export function CheckTransaction(queryParameters: URLSearchParams) {
  if (queryParameters.get("transaction") != null) {
    // Check pending transaction
    CheckPendingTransaction()
      .then(async () => {
        SetDirty();
      })
      .catch(console.error);
  }
}

export async function HandleAuth(
  queryParams: URLSearchParams,
  userData: User,
  dispatch: Dispatch<UnknownAction>,
  navigate: NavigateFunction,
) {
  const userValue = queryParams.get("user");
  if (userValue) {
    localStorage.setItem("invitation", userValue);
  }

  const source = queryParams.get("source");
  let oauthToken = queryParams.get("auth");

  // Decode the token if it comes from the Telegram mini-app
  if (source === "telegram" && oauthToken) {
    oauthToken = decodeURIComponent(oauthToken);

    oauthToken = oauthToken.trim().replace(/^"|"$/g, '').replace(/\n/g, '');

    const jwtPattern = /^[A-Za-z0-9\-_]+\.[A-Za-z0-9\-_]+\.[A-Za-z0-9\-_]+/;
    const matches = oauthToken.match(jwtPattern);
    oauthToken = matches ? matches[0] : null

    
  }

  // Continue with your existing logic
  if (oauthToken && oauthToken !== "error") {
    localStorage.setItem("oauthToken", oauthToken);
    await DownloadData(dispatch);
    await DownloadSubInfo(dispatch);

    const newUser = queryParams.get("new");
    if (newUser) {
      if (localStorage.getItem("invitation")) {
        ValidateInvitation(oauthToken);
      }
      dispatch(openOnboarding());
    }
    let originAccessUrl = localStorage.getItem("originAccessUrl") || "/";
    const baseUrl = window.location.origin;
    if (originAccessUrl.startsWith(baseUrl)) {
      originAccessUrl = originAccessUrl.replace(baseUrl, "");
    }
    navigate(originAccessUrl);
  } else {
    if (localStorage.getItem("oauthToken") !== "" && userData?.Name === "") {
      await DownloadData(dispatch);
      await DownloadSubInfo(dispatch);
    }
  }
}

export async function FetchUserById(userId: number): Promise<User> {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem("oauthToken")}` },
  };
  const response = await axios.get(
    `${import.meta.env.VITE_SERVER_ADDRESS}getUserById?userId=${userId}`,
    config,
  );
  if (response.status === 200) {
    return response.data as User;
  } else {
    throw new Error("User Not Retrieved Correctly");
  }
}

async function fetchUserDataById(
  userId: number,
): Promise<UserData | undefined> {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oauthToken")}`,
      },
    };
    const response = await axios.get(
      `${import.meta.env.VITE_SERVER_ADDRESS}getUserDataById?userId=${userId}`,
      config,
    );
    if (response.status === 200) {
      return response.data as UserData;
    }
  } catch (error) {
    console.error("An error occurred:", error);
  }
}

export async function SaveCreatedVoice(userVoice)
{
  const config = { headers: { "Authorization": "Bearer " + localStorage.getItem("oauthToken") } };
  return axios.post(`${import.meta.env.VITE_SERVER_ADDRESS}saveUserVoice`, userVoice, config);
}