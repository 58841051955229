import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./CommonBtn.css";

interface ComponentProps {
    child: React.ReactNode,
    className: string,
    linkName?: string,
}

function FourthButton(props: ComponentProps) {
  const { child, className, linkName } = props;
  return (
    <div className={`btnImgwrap ${className}`}>
      <span><Image src="/assets/images/btn-left-after.svg" /></span>
     
        <Link className="btn-primary"  to="javascript:void(0)">{child}</Link>
      
      <span><Image src="/assets/images/btn-right-after.svg" /></span>
    </div>
  );
}

export default FourthButton;
