// Chatbot functions to interact with the API using chatbot related functions.
import axios from 'axios';
import Subcategory from '../Models/Subcategory';
import {ClassifyVoice, GenerateLongDescription, SummarizeDescription} from '../Pages/ChatScreenPage/utils';
import ChatbotVoice from '../Models/ChatbotVoice';
import UserChatbot from '../Models/UserChatbot';

export type ChatbotData = {
  id: number;
  name: string;
  category_id: number;
  img: string;
  description: string;
  system_prompt: string;
  audio_url: string;
  source: number;
  popularity: number;
  voice: number;
  character_length: number;
  long_description: string;
  categories: string;
  rag: boolean;
  chatbot_id: number;
  subcategory_id: number;
  string_id: string;
};

export function SerializeChatbot(chatbot: ChatbotData): string {
  return JSON.stringify(chatbot);
}

export async function GetChatbotList(): Promise<ChatbotData[]> {

  try {
    const response = await axios.get(`${import.meta.env.VITE_SERVER_ADDRESS}getMostUsedChatbots`);


    const chatbotsData = response.data.map(chatbot => {
      return {
        ...chatbot,
        id: chatbot.chatbot_id // Renaming 'chatbot_id' to 'id'
      };
    });

    return chatbotsData as ChatbotData[];
  } catch (error) {
    console.error("Error fetching chatbot list: ", error);
    return [];
  }
}

export async function GetLatestUserCreatedChatbots(): Promise<UserChatbot[]> {
  try {
    const response = await axios.get(`${import.meta.env.VITE_SERVER_ADDRESS}getLatestUserCreatedChatbots`);
    return response.data as UserChatbot[];
  } catch (error) {
    console.error("Error fetching chatbot list: ", error);
    return [];
  }

}

export async function GetLocalUserChatbots(): Promise<ChatbotData[]> {

  try {
    const config = { headers: { "Authorization": "Bearer " + localStorage.getItem("oauthToken"), }, };
    const response = await axios.get(`${import.meta.env.VITE_SERVER_ADDRESS}getLocalUserChatbots`, config);
    const data = response.data;

    const chatbots: ChatbotData[] = [];
    data.forEach((item: ChatbotData) => {
      chatbots.push(item);
    });

    return chatbots;
  } catch (error) {
    console.error("Error fetching user chatbot list: ", error);
    return [];
  }
}

export async function GetUserChatbots(id: number): Promise<ChatbotData[]> {

  try {
    const response = await axios.get(`${import.meta.env.VITE_SERVER_ADDRESS}getUserChatbots?created_by=${id}`);
    const data = response.data;
    const chatbots: ChatbotData[] = [];

    data.forEach((item: ChatbotData) => {
      chatbots.push(item);
    });

    return chatbots;
  } catch (error) {
    console.error("Error fetching user chatbot list: ", error);
    return [];
  }
}

export async function GetFavouriteChatbotList() {
  try {
    const config = { headers: { "Authorization": "Bearer " + localStorage.getItem("oauthToken"), }, };
    const response = await axios.get(`${import.meta.env.VITE_SERVER_ADDRESS}getFavouriteChatbots`, config);
    return response.data;

  } catch (error) {
    console.error("Error fetching user chatbot list: ", error);
    return [];
  }
}

export type RecentChatbots = {
  chatbot_id: number;
  name: string;
  img: string;
  user_created: boolean;
  description: string;
}

export async function GetRecentChatbotList(): Promise<RecentChatbots[]> {
  try {
    const config = { headers: { "Authorization": "Bearer " + localStorage.getItem("oauthToken"), }, };
    const response = await axios.get(`${import.meta.env.VITE_SERVER_ADDRESS}getRecentChatbots`, config);
    return response.data as RecentChatbots[];
  } catch (error) {
    console.error("Error fetching user chatbot list: ", error);
    return [];
  }
}

export async function GetCurrentChatbot() {
  try {
    const config = { headers: { "Authorization": "Bearer " + localStorage.getItem("oauthToken"), }, };
    const response = await axios.get(`${import.meta.env.VITE_SERVER_ADDRESS}getCurrentChatbot`, config);
    return response.data;
  } catch (error) {
    //console.error("Error fetching current chatbot: ", error);
    return null;
  }
}

export async function GetFeaturedChatbot(id: number): Promise<ChatbotData> {
  let chatbotData: ChatbotData;

  try {
    const response = await axios.get(`${import.meta.env.VITE_SERVER_ADDRESS}getFeaturedChatbotById?chatId=${id}`);
    chatbotData = response.data as ChatbotData;
    if (chatbotData.audio_url === "" || chatbotData.audio_url === null || chatbotData.audio_url === undefined || chatbotData.description === "" || chatbotData.description === null || chatbotData.description === undefined || chatbotData.description === "test" || chatbotData.long_description === "" || chatbotData.long_description === null || chatbotData.long_description === undefined) {
      //await CheckChatbotDescriptionAndVoice(chatbotData);
    }
    return chatbotData;
  } catch (error) {
    console.error("Error fetching chatbot by ID: ", error);
    return chatbotData;
  }
}
export async function GetFeaturedChatbotByName(name: string): Promise<ChatbotData> {
  let chatbotData: ChatbotData;

  try {
    const response = await axios.get(`${import.meta.env.VITE_SERVER_ADDRESS}getFeaturedChatbotByName?name=${name}`);
    chatbotData = response.data as ChatbotData;
    if (chatbotData.audio_url === "" || chatbotData.audio_url === null || chatbotData.audio_url === undefined || chatbotData.description === "" || chatbotData.description === null || chatbotData.description === undefined || chatbotData.description === "test" || chatbotData.long_description === "" || chatbotData.long_description === null || chatbotData.long_description === undefined) {
      //await CheckChatbotDescriptionAndVoice(chatbotData);
    }
    return chatbotData;
  } catch (error) {
    console.error("Error fetching chatbot by ID: ", error);
    return chatbotData;
  }
}

export async function GetRandomParentChatbots(id: number, isUserCreated: boolean = false, isFeatured: boolean = false): Promise<ChatbotData> {
  let chatbotData: ChatbotData;

  try {
    const response = await axios.get(`${import.meta.env.VITE_SERVER_ADDRESS}getTopChatbots`);
    chatbotData = response.data as ChatbotData;

    return chatbotData;
  } catch (error) {
    console.error("Error fetching chatbot by ID: ", error);
    return chatbotData;
  }
}

export async function GetUserChatbotById(id: number) : Promise<UserChatbot>
{
  let chatbotData: UserChatbot;

  try {
    const response = await axios.get(`${import.meta.env.VITE_SERVER_ADDRESS}getUserChatbotById?chatId=${id}`);
    chatbotData = response.data as UserChatbot;
    return chatbotData;
  } catch (error) {
    console.error("Error fetching chatbot by ID: ", error);
    return chatbotData;
  }
}


export type ConversationData = {
  id: number;
  ChatHeader: string;
  ConversationId: number;
};

export type StatsData = {
  "sent_messages_audio": number,
  "chat_id": number | undefined,
  "creator_user_id": number | undefined,
  "user_id": number | undefined,
  "session_id": number,
  "conversation_id": number,
  "first": boolean,
  "last_message_sender": string | undefined,
  "last_message_chat": string,
  "delete_stat": boolean,
}

export async function GetConversations(chatId: number): Promise<ConversationData> {
  const config = { headers: { "Authorization": "Bearer " + localStorage.getItem("oauthToken"), }, };
  try {
    const response = await axios.post(`${import.meta.env.VITE_SERVER_ADDRESS}getConversations`, chatId, config);
    return response.data;
  } catch (error) {
    console.error("Error fetching chat conversations: ", error);
    return [];
  }
}

const subcategories: Subcategory[] = [];
export async function GetParentCategories(page: number, limit: number): Promise<Subcategory[]> {

  try {
    const response = await axios.get(`${import.meta.env.VITE_SERVER_ADDRESS}getCategories?page=${page}&limit=${limit}`);

    const data = response.data;

    subcategories.length = 0;

    // data.splice(0, page * limit)
    data.forEach((item: Subcategory) => {

      /* removing redundant categories, need to remove these categories from backend to not show */
      if (item.ParentCategoryId == 1) {

      } else if (item.ParentCategoryId == 11) {

      } else if (item.ParentCategoryId == 25) {

      } else if (item.ParentCategoryId == 18) {

      } else if (item.ParentCategoryId == 8) {

      } else if (item.ParentCategoryId == 23) {

      } else if (item.ParentCategoryId == 21) {

      }
      else {
        subcategories.push(item);
      }

    });

    return subcategories.reverse();
  } catch (error) {
    console.error("Error fetching Categories list: ", error);
    return [];
  }
}

export async function GetUserCategories(): Promise<Subcategory[]> {

  try {
    const response = await axios.get(`${import.meta.env.VITE_SERVER_ADDRESS}getUserCategories`);
    return response.data as Subcategory[];
  } catch (error) {
    console.error("Error fetching user categories list: ", error);
    return [];
  }
}

export async function GetUserName(id :number): Promise<string> {

  try {
    const response = await axios.post(`${import.meta.env.VITE_SERVER_ADDRESS}getUserName`, id);
    return response.data as string;
  } catch (error) {
    console.error(`Error fetching user name with id ${id}: `, error);
    return "";
  }
}


export async function GetSubCategoriesFromParent(page: number, limit: number, parentId: number): Promise<Subcategory[]> {

  try {
    let response;

    response = await axios.get(`${import.meta.env.VITE_SERVER_ADDRESS}getSubCategories?page=${page}&limit=${limit}&parentId=${parentId}`);

    const data = response.data;

    subcategories.length = 0;

    data.forEach((item: Subcategory) => {
      subcategories.push(item);
    });


    return subcategories.reverse();
  } catch (error) {
    console.error("Error fetching Categories list: ", error);
    return [];
  }
}

export async function GetChatbotsBySubcategory(catId: number, pageNo: number, limit: number): Promise<ChatbotData[]> {
  try {
    const response = await axios.get(`${import.meta.env.VITE_SERVER_ADDRESS}getSubCategoryChatbots?subId=${catId}&page=${pageNo}&limit=${limit}`);
    const chatbotsData = response.data.map(chatbot => {
      return {
        ...chatbot,
        id: chatbot.chatbot_id // Renaming 'chatbot_id' to 'id'
      };
    });

    return chatbotsData as ChatbotData[];
  } catch (error) {
    console.error("Error fetching chatbot list: ", error);
    return [];
  }
}
export async function GetPremiumChatbots(): Promise<ChatbotData[]> {
  try {
    const response = await axios.get(`${import.meta.env.VITE_SERVER_ADDRESS}getPremiumChatbots`);
    return response.data as ChatbotData[];
  } catch (error) {
    console.error("Error fetching chatbot list: ", error);
    return [];
  }
}

export async function GetChatbotsByParent(catId: number, pageNo: number, limit: number): Promise<ChatbotData[]> {
  try {
    const response = await axios.get(`${import.meta.env.VITE_SERVER_ADDRESS}getParentCategoryChatbots?parentCategoryId=${catId}&page=${pageNo}&limit=${limit}`);
    const chatbotsData = response.data.map(chatbot => {
      return {
        ...chatbot,
        id: chatbot.chatbot_id // Renaming 'chatbot_id' to 'id'
      };
    });
    return chatbotsData as ChatbotData[];
  } catch (error) {
    console.error("Error fetching chatbot list: ", error);
    return [];
  }
}

export type ChatbotSearchData = {
  id: number;
  name: string;
  img: string;
}


export async function GetSearchedData(query: string): Promise<ChatbotData[]> {
  try {
    const data = {
      "input": {
        "query": query
      }
    };
    const response = await fetch("https://api.runpod.ai/v2/avmd7oorsqg6xz/runsync", {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Authorization": "XNLHZ5FP018JAYKXTFE4AJJHR32E1BR1DLUPT1VJ", // This should be stored securely
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    });
    const responseDataTmp = await response.json();
    const responseData = JSON.parse(responseDataTmp["output"]["results"]);
    // return JSON.parse(responseData['output']['results']);

    let chatbots = responseData as ChatbotData[];

    chatbots = chatbots.filter(chatbot => chatbot.description !== "nan");

    for (const chatbot of chatbots) {
      chatbot.chatbot_id = chatbot.id;
    }
    return chatbots;
  } catch (error) {
    console.error("Error fetching chatbot list: ", error);
    return [];
  }
}

export async function GetSemanticSearchList(query: string): Promise<ChatbotSearchData[]> {
  try {
    const data = {
      "input": {
        "query": query
      }
    };
    const response = await fetch("https://api.runpod.ai/v2/avmd7oorsqg6xz/runsync", {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Authorization": "XNLHZ5FP018JAYKXTFE4AJJHR32E1BR1DLUPT1VJ", // This should be stored securely
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    });
    const responseData = await response.json();
    return JSON.parse(responseData["output"]["results"]);
  } catch (error) {
    console.error("Error fetching chatbot list: ", error);
    return [];
  }
}

export async function CheckIfUserPurchasedPremiumBot(chatbotId: number): Promise<boolean> {
  try {
    const config = { headers: { "Authorization": "Bearer " + localStorage.getItem("oauthToken"), }, };
    const response = await axios.post(`${import.meta.env.VITE_SERVER_ADDRESS}checkUserCanUsePremiumChatbot`, chatbotId, config);
    return response.data;
  } catch (error) {
    console.error("Error fetching chatbot list: ", error);
    return false;
  }
}

export async function GetRandomChatbots(page: number, limit: number): Promise<ChatbotData[]> {
  try {
    const response = await axios.get(`${import.meta.env.VITE_SERVER_ADDRESS}getAllChatbots?page=${page}&limit=${limit}`);
    return response.data as ChatbotData[];
  } catch (error) {
    console.error("Error fetching chatbot list: ", error);
    return [];
  }

}

async function CheckChatbotDescriptionAndVoice(chatbot: ChatbotData) {
  let newData: boolean = false;
  if (!chatbot.description || chatbot.description.trim() === "" || chatbot.description === "test") {
    newData = true;
    await SummarizeDescription(chatbot.system_prompt).then(description => {
      chatbot.description = description;
    });
  }

  if (!chatbot.audio_url || chatbot.audio_url.trim() === "") {
    newData = true;
    await ClassifyVoice(chatbot.system_prompt).then(audio_url => {
      chatbot.audio_url = audio_url;
    });
  }

  if (!chatbot.long_description || chatbot.long_description.trim() === "") {
    newData = true;
    await GenerateLongDescription(chatbot.system_prompt).then(long_description => {
      chatbot.long_description = long_description;
    });
  }

  if (newData) {
    await axios.post(`${import.meta.env.VITE_SERVER_ADDRESS}updateChatbot`, chatbot);
  }
}

export async function UpdateUserChatbot(chatbot: UserChatbot) {
  try {
    await axios.post(`${import.meta.env.VITE_SERVER_ADDRESS}updateUserChatbot`, chatbot);
  } catch (error) {
    console.error("Error fetching chatbot list: ", error);
    return false;
  }
}

let botVoices: ChatbotVoice[] = [];
export async function GetChatbotVoices(): Promise<ChatbotVoice[]> {
  if (botVoices.length > 0) {
    return botVoices;
  }
  try {
    const response = await axios.get(`${import.meta.env.VITE_SERVER_ADDRESS}getVoices`);
    botVoices = response.data as ChatbotVoice[];
    return botVoices;
  } catch (error) {
    console.error("Error fetching chatbot by ID: ", error);
    return [];
  }
}

export async function GetChatbotVoiceByUrl(url: string): Promise<ChatbotVoice> {
  try {
    const urlToSend = "\"" + url + "\"";
    const response = await axios.post(`${import.meta.env.VITE_SERVER_ADDRESS}getVoiceByUrl`, urlToSend);
    return response.data as ChatbotVoice;
  } catch (error) {
    console.error("Error fetching chatbot by ID: ", error);
    return {
      Accent: "", Category: "", Descriptive: "", Gender: "", Id: 0, PlayHtVoice: "", PreviewUrl: "", UseCase: "",
      Name: "User Cloned Voice"
    };
  }
}

export function classifyLinkType(url: string) {
  if (/^(https?\:\/\/)?(www\.youtube\.com|youtu\.?be)\/.+/.test(url)) {
    return "youtube";
  } else if (/^(https?\:\/\/)?(www\.gitbook\.com|gitbook\.io)\/.+/.test(url)) {
    return "gitbook";
  } else if (/^(https?:\/\/)(www\.)?github\.com\/[\w.-]+\/[\w.-]+/.test(url)) {
    return "github";
  } else if (/^(https?\:\/\/)?(www\.imsdb\.com)\/.+/.test(url)) {
    return "IMSDB";
  } else if (/^(https?\:\/\/)?(news\.ycombinator\.com)\/.+/.test(url)) {
    return "hackernews";
  }
  return "link";  // Default type if no other type matches
}


export const readFileAsDataURL = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
};

export async function HasUserTalkedWithChatbot(chatbotId : number) : Promise<boolean>
{
  try {
    const chatbots = await GetRecentChatbotList();
    return chatbots.some(chatbot => chatbot.chatbot_id === chatbotId);
  }
  catch (error) {
    console.error("Error fetching chatbot list: ", error);
    return false;
  }
}

