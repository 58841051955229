import { Image } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import { ChatbotData } from '../../Utils/ChatbotUtils';
import { useEffect, useState } from "react";
import SmoothImage from "react-smooth-image";
import UserChatbot from '../../Models/UserChatbot';
import { useDispatch } from "react-redux";
import { showPopUpAction } from "../../Redux/Slices/loginPopUpSlice";

export default function CompanionCard({ chatbotData }: { chatbotData: ChatbotData | UserChatbot }): JSX.Element {
  const [isActive, setIsActive] = useState(false);
  const dispatch = useDispatch();
  const toggleFavouriteChatbot = (e) => {
    e.stopPropagation()
    if (localStorage.getItem("oauthToken")) {
      setIsActive(!isActive);
    }
    else{
      dispatch(showPopUpAction(true));
    }
  };
  const navigate = useNavigate();

  const handleLinkClick = () => {
    if (chatbotData?.user_id && chatbotData?.user_id >= 0) {
      // User Created Chatbot
      navigate(`/chat/${chatbotData?.name}?id=${chatbotData?.id}`);
    }
    else {
      navigate(`/chat/${chatbotData?.string_id}`);
    }
  };
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1050);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>

      <div className="CompanionShorts-Main">
        {loading ? (
          <Link to="#">
            <div className="FCompanions-Img skeleton">
              <Image src={chatbotData?.img} className="opacity-0" />
              {/* <span><i><Icon icon="heroicons:sparkles-solid" /></i> {t("premium")}</span> */}
            </div>
            <div className="FCompanions-Info">
              <div className="FCompanions-Info-Title">
                <div className="skeleton ">
                  <h6 className="opacity-0">{chatbotData?.name}</h6>
                </div>
                <div className="skeleton ml-auto">
                  <Link to="javascript:void(0)" onClick={toggleFavouriteChatbot} className={`FavoriteLink opacity-0 favicon ${isActive ? 'active' : ''}`}> <Icon icon={isActive ? "mingcute:star-fill" : "mingcute:star-line"} /></Link>
                  <Link to="javascript:void(0)" onClick={handleLinkClick} className="opacity-0"><Icon icon="ep:top-right" /></Link>
                </div>
              </div>
              <div className="skeleton ">
                <p className="opacity-0">"build in seconds any kind of voice-powered AI agents and AI clones"</p>
              </div>
              {/* <div className="FCompanions-Wrap">
                <div className="skeleton ">
                  <pre className="opacity-0"><span><Image src="/images/OrboxIcon.jpg" /></span>Orbofi</pre>
                </div>
                <div className="skeleton ">
                  <ul className="opacity-0">
                    <li className="FCompanions-layer"><i><Icon icon="bx:layer" /></i> 95<small>/100 </small></li>
                    <li className="FCompanions-users"><i><Icon icon="lucide:users" /></i> 0</li>
                    <li className="FCompanions-fire"><i><Icon icon="ant-design:fire-outlined" /></i> 0</li>
                  </ul>
                </div>
              </div> */}
            </div>
          </Link>
        ) : (

          <Link to='javascript:void(0)' onClick={handleLinkClick}>
            <div className="CompanionShorts-Img">
              <SmoothImage transitionTime={1.0}
                alt={chatbotData?.name}
                src={chatbotData?.img}
                width={100} // Example width, adjust as needed
                height={100} // Example height, adjust as needed
              />
              {/* <span>{chatbotData.caption}</span> */}

            </div>
            <div className="CompanionShorts-Info">
              <div className="CompanionShorts-Info-Title">
                <h5> {chatbotData?.name} {chatbotData?.source === 4 ? (<span><Icon style={{color:"#b39800", fontSize: '16px'}} icon="fluent:checkmark-starburst-16-filled" /></span>) :  ''}</h5>
                <Link to="javascript:void(0)" onClick={toggleFavouriteChatbot} className={`FavoriteLink favicon ${isActive ? 'active' : ''}`}> <Icon icon={isActive ? "mingcute:star-fill" : "mingcute:star-line"} /></Link>
                <Link to='javascript:void(0)'><Icon icon="ep:top-right" /></Link>
              </div>
              {/* <p>{chatbot.description}</p> */}
              <p>{chatbotData?.description.substring(0, 60) + (chatbotData.description.length > 60 ? "..." : "") || "build in seconds any kind of voice-powered AI agents and AI clones"}</p>
              {/* <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Iste, aliquid?</p> */}
              {/* <div className="CompanionShorts-UserProfile">
                <i><Image src={"/images/orbofi.png"} /></i>
                <Link to={`/user/${chatbotData?.user_name}`}><h6>{chatbotData?.user_name}</h6></Link>
              </div>
              <ul className="CompanionShorts-Wraps">
                <li className="FCompanions-layer"><i><Icon icon="bx:layer" /></i> 95<small>/100 </small></li>
                <li className="FCompanions-users"><i><Icon icon="lucide:users" /></i>0</li>
                <li className="FCompanions-fire"><i><Icon icon="ant-design:fire-outlined" /></i>0</li>
              </ul> */}
            </div>
          </Link>
        )}
      </div>

    </>
  );
}