import { Image, Nav, Tab } from "react-bootstrap";
import SubscriptionBox from "./Components/SubscriptionBox/SubscriptionBox";
import OrboxPriceBox from "./Components/OrboxPriceBox/OrboxPriceBox";
import "./PricingPage.css";
import PricingSidebar from "./PricingSidebar";
import { useEffect, useState } from "react";
import Products from "../../Models/Products";
import axios from "axios";
import PremiumProductBox from "./Components/PremiumProductBox/PremiumProductBox";
import PremiumProductBoxFaq from "./Components/PremiumProductBox/PremiumProductBoxFaq";
import PricingTabs from "./Components/PricingTabs/PricingTabs";




function onPaymentToggle() {
  const bodyElement = document.querySelector("body");
  if (bodyElement) {
    bodyElement.classList.toggle("paymentSidebarShow");
  }
}

function PricingPage() {
  const [currentProduct, setCurrentProduct] = useState(new Products());
  return (
    <>
      <div className="MainContent d-margin page-bg">
        <span className="top-left-bg"> <Image src="/assets/images/top-left-bg.svg" /> </span>
        <span className="top-right-bg"> <Image src="/assets/images/top-right-bg.svg" /> </span>
        <span className="bottom-left-bg"> <Image src="/assets/images/bottom-left-bg.svg" /> </span>
        <span className="bottom-right-bg"> <Image src="/assets/images/bottom-right-bg.svg" /> </span>
        <div className="padding-16">
          <div className="pricing-main-title">
            <h6>AI IT NOW</h6>
            <h3>Pick a plan that’s right for you</h3>
            <p>Get the right plan for your business. Plan can be upgraded in the future</p>
          </div>
          <div style={{maxWidth: '1480px', margin : '0 auto'}}>
            <PricingTabs isFaq={true} onPaymentToggle={onPaymentToggle} setCurrentProduct={setCurrentProduct}  />
          </div>
        </div>
      </div>
      <PricingSidebar onPaymentToggle={onPaymentToggle} product={currentProduct} />
    </>
  );
}

export default PricingPage;
