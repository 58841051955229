
import "./Loader.css";
import Lottie from 'react-lottie';
import animationData from './logo.json';
import React from 'react'
import ReactPlayer from 'react-player/lazy'

const Loader = (props) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };
  return (
    // <div className="loaderMain">
    //   <Lottie options={defaultOptions} />
    // </div>
    <div className="LoaderMains">
      <ReactPlayer url='/Orbofi-logo.webm' playing={true} loop={true} muted />
    </div>
    // <svg
    //   id="Layer_1"
    //   xmlns="http://www.w3.org/2000/svg"
    //   xmlnsXlink="http://www.w3.org/1999/xlink"
    //   viewBox="0 0 827.91 738.53"
    //   width={200}
    //   height={200}
    //   {...props}
    // >
    //   <defs>
    //     <style>
    //       {
    //         ".cls-1{fill:url(#linear-gradient);}.cls-1,.cls-2,.cls-3,.cls-4,.cls-5,.cls-6,.cls-7,.cls-8{stroke-width:0px;}.cls-2{fill:url(#radial-gradient);}.cls-3{fill:url(#linear-gradient-4);}.cls-4{fill:url(#linear-gradient-2);}.cls-5{fill:url(#linear-gradient-3);}.cls-6{fill:url(#linear-gradient-5);}.cls-7{fill:url(#linear-gradient-6);}.cls-8{fill:#fff;}"
    //       }
    //     </style>
    //     <linearGradient
    //       id="linear-gradient"
    //       x1={626.85}
    //       y1={87.63}
    //       x2={263.91}
    //       y2={407.29}
    //       gradientUnits="userSpaceOnUse"
    //     >
    //       <stop offset={0} stopColor="#e4f7ed" />
    //       <stop offset={0.99} stopColor="#76d6a6" />
    //     </linearGradient>
    //     <radialGradient
    //       id="radial-gradient"
    //       cx={430.46}
    //       cy={363.39}
    //       fx={430.46}
    //       fy={363.39}
    //       r={72}
    //       gradientUnits="userSpaceOnUse"
    //     >
    //       <stop offset={0.43} stopColor="#3fc4ff" />
    //       <stop offset={0.99} stopColor="#4e5afe" />
    //     </radialGradient>
    //     <linearGradient
    //       id="linear-gradient-2"
    //       x1={521.54}
    //       y1={-31.95}
    //       x2={158.59}
    //       y2={287.71}
    //       xlinkHref="#linear-gradient"
    //     />
    //     <linearGradient
    //       id="linear-gradient-3"
    //       x1={545.48}
    //       y1={-4.76}
    //       x2={182.53}
    //       y2={314.9}
    //       xlinkHref="#linear-gradient"
    //     />
    //     <linearGradient
    //       id="linear-gradient-4"
    //       x1={479.89}
    //       y1={-79.23}
    //       x2={116.94}
    //       y2={240.43}
    //       xlinkHref="#linear-gradient"
    //     />
    //     <linearGradient
    //       id="linear-gradient-5"
    //       x1={604.91}
    //       y1={62.72}
    //       x2={241.96}
    //       y2={382.38}
    //       xlinkHref="#linear-gradient"
    //     />
    //     <linearGradient
    //       id="linear-gradient-6"
    //       x1={617.07}
    //       y1={76.52}
    //       x2={254.12}
    //       y2={396.18}
    //       xlinkHref="#linear-gradient"
    //     />
    //   </defs>
    //   <path
    //     className="cls-1 svg-elem-1"
    //     d="m726.54,221.19H134.51c-7.2,0-12.93,7.38-12.93,16.23,0,4.41,1.47,8.54,3.76,11.48,2.31,2.78,5.57,4.6,9.17,4.6h10.17c-7.04,0-12.79,7.2-12.79,16.05,0,4.6,1.31,8.69,3.78,11.48,2.29,2.94,5.41,4.75,9.01,4.75h10.32c-7.04,0-12.77,7.2-12.77,16.05,0,4.44,1.31,8.54,3.6,11.48,2.47,2.94,5.57,4.75,9.17,4.75h137.7c-1.31,6.38-2.31,12.95-2.63,19.83l-1,18.52c-4.1,80.8,60.47,148.68,141.3,148.68s145.53-67.88,141.44-148.68l-.97-18.52c-.34-6.88-1.15-13.45-2.63-19.83h137.68c3.62,0,6.91-1.81,9.03-4.75,2.45-2.94,3.76-7.04,3.76-11.48,0-8.85-5.73-16.05-12.79-16.05h10.35c3.6,0,6.7-1.81,9.01-4.75,2.29-2.78,3.76-6.88,3.76-11.48,0-8.85-5.73-16.05-12.77-16.05h10.3c3.44,0,6.57-1.81,9.03-4.6,2.29-2.94,3.76-7.06,3.76-11.48,0-8.85-5.73-16.23-12.79-16.23Zm-208.32,146.21c-2.13,45.08-38.51,81.62-83.59,83.77-51.8,2.29-94.41-40.32-91.94-91.97,2.11-45.05,38.51-81.62,83.59-83.59,51.78-2.47,94.41,40.16,91.94,91.79Z"
    //   />
    //   <path
    //     className="cls-2 svg-elem-2"
    //     d="m518.22,367.41c-2.13,45.08-38.51,81.62-83.59,83.77-51.8,2.29-94.41-40.32-91.94-91.97,2.11-45.05,38.51-81.62,83.59-83.59,51.78-2.47,94.41,40.16,91.94,91.79Z"
    //   />
    //   <path
    //     className="cls-4 svg-elem-3"
    //     d="m324.65,145.05c-5.34-13.83-8.05-29.86-8.05-47.65s2.71-33.71,8.05-47.53c.5-1.28,1.02-2.54,1.56-3.78-13.61,4.41-25.27,12.33-33.64,24.12,9.56,6.72,15.69,18.28,15.69,35.36,0,23.13-11.14,36.06-26.8,40.67,9.05,36.86,38.4,53.2,73.24,53.2,7.83,0,15.38-.83,22.49-2.52-11.53-4.28-21.71-10.45-30.25-18.41-9.7-9.03-17.19-20.29-22.28-33.46Z"
    //   />
    //   <path
    //     className="cls-5 svg-elem-4"
    //     d="m511.41,43.25c-4.1-7.6-9.25-14.3-15.43-20.07C479.74,8.02,456.72,0,429.42,0s-50.31,8.02-66.56,23.18c-6.18,5.77-11.33,12.47-15.42,20.07-7.95,14.73-11.96,32.85-11.96,54.15,0,32.39,9.21,57.4,27.38,74.32,10.37,9.66,23.53,16.41,38.82,20.01,8.61,2.03,17.89,3.07,27.74,3.07s19.13-1.05,27.74-3.07c15.29-3.61,28.46-10.35,38.83-20.01,18.17-16.92,27.38-41.93,27.38-74.32,0-21.3-4.01-39.42-11.96-54.15Zm-71.98,61.82c-2.69.41-5.47.62-8.32.62-4.03,0-7.91-.4-11.6-1.24-17.1-3.85-29.58-16.91-29.58-41.38,0-6.48.88-12.17,2.49-17.1,5.03-15.31,17.14-23.31,31.92-25.12-8.02,2.2-13.77,8.7-13.77,20.38,0,14.83,9.18,21.26,20.53,21.26,3.55,0,6.87-.63,9.79-1.94,2.69-1.22,5.03-3.03,6.82-5.48,2.47-3.37,3.92-7.93,3.92-13.84,0-11.68-5.75-18.18-13.78-20.38,14.49,1.78,26.42,9.49,31.61,24.22,1.81,5.13,2.81,11.11,2.81,18,0,26.01-14.1,39.13-32.86,42.01Z"
    //   />
    //   <path
    //     className="cls-3 svg-elem-5"
    //     d="m280.65,82.26c-2.24-.67-4.67-.99-7.21-.99-11.38,0-20.59,6.5-20.59,21.31s9.21,21.31,20.59,21.31c.02,0,.03,0,.05,0,11.36-.02,20.54-6.46,20.54-21.31,0-11.51-5.56-18-13.38-20.32Z"
    //   />
    //   <path
    //     className="cls-6 svg-elem-6"
    //     d="m552.66,105.58c0-17.09,6.13-28.64,15.69-35.36-8.37-11.79-20.03-19.71-33.64-24.12.54,1.24,1.07,2.5,1.56,3.78,5.34,13.82,8.05,29.81,8.05,47.53s-2.71,33.82-8.05,47.65c-5.09,13.17-12.59,24.43-22.28,33.46-8.54,7.96-18.73,14.12-30.25,18.41,7.11,1.7,14.66,2.52,22.49,2.52,34.84,0,64.19-16.34,73.24-53.2-15.66-4.61-26.8-17.54-26.8-40.67Z"
    //   />
    //   <path
    //     className="cls-7 svg-elem-7"
    //     d="m587.47,81.27c-2.54,0-4.97.33-7.21.99-7.82,2.32-13.38,8.81-13.38,20.32,0,14.85,9.19,21.29,20.54,21.31.02,0,.03,0,.05,0,11.38,0,20.59-6.44,20.59-21.31s-9.21-21.31-20.59-21.31Z"
    //   />
    //   <path
    //     className="cls-8 svg-elem-8"
    //     d="m136.63,612.21c15.61,13.77,17.11,37.97,17.11,55.88s-1.71,42.11-17.11,55.88c-16.46,14.56-42.12,14.56-59.66,14.56s-43.19.2-59.66-14.56C.21,708.63,0,683.64,0,668.09c0-18.69.86-41.91,17.32-55.88,16.46-14.76,41.48-14.56,59.66-14.56s43.19-.2,59.66,14.56Zm-98.36,94.25c8.55,10.43,23.09,11.41,38.49,11.41s29.51-.98,38.7-11.41c4.92-5.71,9.19-16.33,9.19-38.37,0-23.81-4.49-33.25-9.19-38.56-8.77-10.03-23.31-11.22-38.7-11.22s-29.72,1.18-38.49,11.22c-5.77,6.89-9.19,17.31-9.19,38.56,0,22.43,4.28,32.86,9.19,38.37Z"
    //   />
    //   <path
    //     className="cls-8 svg-elem-9"
    //     d="m177.04,622.64c0-5.71.21-12.59,6.2-17.9,5.35-4.72,12.19-5.51,17.96-5.51h70.99c10.26,0,23.52.59,32.07,12.59,7.06,9.44,7.48,20.27,7.48,30.3s-.43,20.86-6.41,31.09c-3.85,6.69-10.9,14.17-28.22,14.17h-19.24v.98l56.88,48.6h-35.49l-49.18-44.66c-3.21-2.95-7.06-7.67-7.06-11.61v-3.15c0-5.51,4.28-9.44,12.62-9.44h28.22c4.92,0,11.33-.59,15.39-6.1,2.99-4.53,3.42-14.17,3.42-18.89,0-11.02-2.14-16.13-5.35-19.09-4.49-4.13-11.33-4.13-15.82-4.13h-45.76c-3.21,0-5.13,0-7.48,2.16-3.21,2.36-2.99,4.72-2.99,7.67v107.23h-28.22v-114.32Z"
    //   />
    //   <path
    //     className="cls-8 svg-elem-10"
    //     d="m461.85,674.59c6.84,7.48,7.06,16.72,7.06,24.6,0,9.25,0,20.07-8.55,28.92-8.55,8.85-18.17,8.85-27.37,8.85h-71.63c-7.48,0-14.33.79-20.31-5.51-1.5-1.57-5.13-6.1-5.13-13.97v-98.38c0-5.31,1.5-10.43,4.7-14.17,4.49-4.92,10.9-5.71,15.82-5.71h73.55c8.34,0,19.03.79,27.15,8.26,8.34,7.67,9.41,20.66,9.41,29.51,0,7.67-.43,15.15-7.06,22.43-4.28,4.72-8.34,6.49-14.11,7.08v.79c7.06.59,12.83,3.54,16.46,7.28Zm-97.71-17.51h54.74c5.77,0,11.33,0,15.39-4.92,1.07-1.38,3.21-4.53,3.21-13.97,0-3.74,0-10.62-4.92-14.76-4.06-3.74-8.98-3.54-13.9-3.54h-46.18c-4.92-1.57-9.19,3.74-8.34,7.67v29.51Zm0,48.99c0,4.53.64,6.69,1.71,8.07,2.35,2.36,6.84,2.16,9.19,2.16h44.47c4.49,0,12.19-.2,16.46-4.92,3.21-3.74,3.85-9.44,3.85-13.97,0-3.94-.43-10.23-3.21-13.97-1.71-2.16-5.99-5.71-15.18-5.71h-57.3v28.33Z"
    //   />
    //   <path
    //     className="cls-8 svg-elem-11"
    //     d="m624.57,612.21c15.61,13.77,17.11,37.97,17.11,55.88s-1.71,42.11-17.11,55.88c-16.46,14.56-42.12,14.56-59.66,14.56s-43.19.2-59.66-14.56c-17.11-15.35-17.32-40.34-17.32-55.88,0-18.69.86-41.91,17.32-55.88,16.46-14.76,41.48-14.56,59.66-14.56s43.19-.2,59.66,14.56Zm-98.36,94.25c8.55,10.43,23.09,11.41,38.49,11.41s29.51-.98,38.7-11.41c4.92-5.71,9.19-16.33,9.19-38.37,0-23.81-4.49-33.25-9.19-38.56-8.77-10.03-23.31-11.22-38.7-11.22s-29.72,1.18-38.49,11.22c-5.77,6.89-9.19,17.31-9.19,38.56,0,22.43,4.28,32.86,9.19,38.37Z"
    //   />
    //   <path
    //     className="cls-8 svg-elem-12"
    //     d="m664.98,736.96v-119.63c0-4.53,1.07-9.25,5.56-13.18,5.13-4.53,10.9-4.92,16.04-4.92h81.52v20.66h-67.84c-1.92,0-3.63-.2-5.35,1.57-1.71,1.57-1.71,3.74-1.71,5.51v29.91h74.89v20.66h-74.89v59.42h-28.22Z"
    //   />
    //   <path
    //     className="cls-8 svg-elem-13"
    //     d="m799.68,736.96v-137.73h28.22v137.73h-28.22Z"
    //   />
    // </svg>

  );
};

export default Loader;
