import { Col, Image, Row } from "react-bootstrap";
import { Icon } from "@iconify/react";
import { Link, useLocation, useParams } from "react-router-dom";
import "./CategoriesBigBox.css";
import { useEffect, useState } from "react";
import Subcategory from "../../Models/Subcategory";
import { GetSubCategoriesFromParent } from "../../Utils/ChatbotUtils";
import InfiniteScroll from "react-infinite-scroll-component";

function CategoriesBigSubcategories() {
  const [subcategories, setSubcategories] = useState<Subcategory[]>([]);
  const location = useLocation();
  const { categoryName } = useParams<{ categoryName: string }>();
  const itemsPerPage = 50;

  const [displayCatNumber, setDisplayCatNumber] = useState(itemsPerPage);
  const [records, setRecords] = useState(itemsPerPage);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  // Default items per page

  useEffect(() => {
    function updateItemsPerPage() {
      const screenWidth = window.innerWidth;
      const isMobileWidth = screenWidth <= 768; // Define mobile threshold
      // // Set itemsPerPage based on screen width and explore page
      if (isMobileWidth) {
        setDisplayCatNumber(31);
      } else {
        setDisplayCatNumber(31);
      }

    }

    updateItemsPerPage();
    window.addEventListener("resize", updateItemsPerPage);
    return () => window.removeEventListener("resize", updateItemsPerPage);
  }, []);
  useEffect(() => {
    fetchSubcategories(0, categoryName ? Number(categoryName) : 0);

  }, []);


  function fetchSubcategories(page: number, categoryName: number) {
    GetSubCategoriesFromParent(page, itemsPerPage, categoryName).then((newCategories: Subcategory[]) => {
      const subCatList = subcategories.concat(newCategories);
      setSubcategories(subCatList);
      setRecords(prevRecords => prevRecords + newCategories?.length);
      setHasMore(newCategories?.length === itemsPerPage);
    });
  }

  const loadMore = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    fetchSubcategories(nextPage, categoryName);
  };

  const [showData, setShowData] = useState(false);

  useEffect(() => {
    // This effect will run every time 'records' changes, 
    // and will set a timeout to show the data after 2 seconds
    const timeout = setTimeout(() => {
      setShowData(true);
    }, 1500);

    return () => clearTimeout(timeout); // Cleanup on component unmount
  }, [records]); // Re-run this effect whenever 'records' changes

  return (
    <>
      <div className="MainContent padding-16 d-margin">
        <div className="maincategory">
          <InfiniteScroll
            dataLength={records}
            className="row CategoriesBigBoxRow"
            next={() => loadMore()}
            hasMore={hasMore}
            // height={window.innerHeight}
            loader={
              <div className="AiImageLoader">
                <div>
                  <div className="nb-spinner"></div>
                </div>
              </div>
            }
          >
            {showData && subcategories.map((subcategory: Subcategory, index) => (
              <SubCategoryBox key={index} subcategoryData={subcategory} location={location} />
            ))}
          </InfiniteScroll>
        </div>
      </div>
    </>
  );
}

function SubCategoryBox({ subcategoryData, location }: { subcategoryData: Subcategory, location: any }): JSX.Element {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <Col lg={3}>

        <div className="CategoriesBigBox-Main">
          <div className="CategoriesBigBox-Image">
            {loading ? (
              <Row >

                {subcategoryData?.CoverImageUrl?.[0] && <Col lg={6} >
                  <div className="CategoriesBigBox-img skeleton">
                    <Link to={`/searchSubcategory?subcat=${subcategoryData?.SubcategoryId}`} state={subcategoryData?.Name} className="opacity-0">
                      <Image src={JSON.parse(subcategoryData?.CoverImageUrl)[0]} />
                    </Link>

                    {/* <Link to={`/chatbots?subcat=${subcategoryData?.id}`}>
                    <Image src={subcategoryData?.CoverImageUrl[0] ? subcategoryData?.CoverImageUrl[0] : ""} />
                  </Link> */}
                  </div>
                </Col>}
                {subcategoryData?.CoverImageUrl?.[1] && <Col lg={6} >
                  <div className="CategoriesBigBox-img skeleton">
                    <Link to={`/searchSubcategory?subcat=${subcategoryData?.SubcategoryId}`} state={subcategoryData?.Name} className="opacity-0">
                      <Image src={JSON.parse(subcategoryData?.CoverImageUrl)[1]} />
                    </Link>
                    {/* <Link to={`/chatbots?subcat=${subcategoryData?.id}`}><Image src={subcategoryData?.CoverImageUrl[1] ? subcategoryData?.CoverImageUrl[1] : ""} /></Link> */}
                  </div>
                </Col>}
                {subcategoryData?.CoverImageUrl?.[2] && <Col lg={6} className="CategoriesBigBox-p-0 ">
                  <div className="CategoriesBigBox-img skeleton">
                    <Link to={`/searchSubcategory?subcat=${subcategoryData?.SubcategoryId}`} state={subcategoryData?.Name} className="opacity-0">
                      <Image src={JSON.parse(subcategoryData?.CoverImageUrl)[2]} />
                    </Link>
                    {/* <Link to={`/chatbots?subcat=${subcategoryData?.id}`}><Image src={subcategoryData?.CoverImageUrl[2]} /></Link> */}
                  </div>
                </Col>}
                {subcategoryData?.CoverImageUrl?.length > 3 && <Col lg={6} className="CategoriesBigBox-p-0 ">
                  <div className="CategoriesBigBox-sub-img-main">
                    <Row >
                      <Col lg={6} >
                        <div className="CategoriesBigBox-sub-Wrap">
                          <div className="CategoriesBigBox-sub-img skeleton">
                            <Link to={`/searchSubcategory?subcat=${subcategoryData?.SubcategoryId}`} state={subcategoryData?.Name} className="opacity-0">
                              <Image src={JSON.parse(subcategoryData?.CoverImageUrl)[3]} />
                            </Link>
                            {/* <Link to={`/chatbots?subcat=${subcategoryData?.id}`}><Image src={subcategoryData?.CoverImageUrl[3]} /></Link> */}
                          </div>
                          <div className="CategoriesBigBox-sub-img skeleton">
                            <Link to={`/searchSubcategory?subcat=${subcategoryData?.SubcategoryId}`} state={subcategoryData?.Name} className="opacity-0">
                              <Image src={JSON.parse(subcategoryData?.CoverImageUrl)[4]} />
                            </Link>
                            {/* <Link to={`/chatbots?subcat=${subcategoryData?.id}`}><Image src={subcategoryData?.CoverImageUrl[4]} /></Link> */}
                          </div>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="CategoriesBigBox-sub-Wrap">
                          {subcategoryData?.CoverImageUrl?.[5] && <div className="CategoriesBigBox-sub-img skeleton">
                            <Link to={`/searchSubcategory?subcat=${subcategoryData?.SubcategoryId}`} state={subcategoryData?.Name} className="opacity-0"><Image src={JSON.parse(subcategoryData?.CoverImageUrl)[5]} /></Link>
                          </div>}
                          <div className="CategoriesBigBox-sub-img-add">
                            <Link to="javascript:void(0)"><Icon icon="mingcute:add-fill" /></Link>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>}
                {subcategoryData?.CoverImageUrl?.length <= 3 && <Col lg={6} className="CategoriesBigBox-p-0 skeleton">
                  <div className="CategoriesBigBox-sub-img-add">
                    <Link to="javascript:void(0)"><Icon icon="mingcute:add-fill" /></Link>
                  </div>
                </Col>}
              </Row>
            ) : (
              <Row>

                {subcategoryData?.CoverImageUrl?.[0] && <Col lg={6}>
                  <div className="CategoriesBigBox-img">
                    <Link to={`/searchSubcategory?subcat=${subcategoryData?.SubcategoryId}`} state={subcategoryData?.Name}>
                      <Image src={JSON.parse(subcategoryData?.CoverImageUrl)[0]} />
                    </Link>

                    {/* <Link to={`/chatbots?subcat=${subcategoryData?.id}`}>
                    <Image src={subcategoryData?.CoverImageUrl[0] ? subcategoryData?.CoverImageUrl[0] : ""} />
                  </Link> */}
                  </div>
                </Col>}
                {subcategoryData?.CoverImageUrl?.[1] && <Col lg={6}>
                  <div className="CategoriesBigBox-img">
                    <Link to={`/searchSubcategory?subcat=${subcategoryData?.SubcategoryId}`} state={subcategoryData?.Name}>
                      <Image src={JSON.parse(subcategoryData?.CoverImageUrl)[1]} />
                    </Link>
                    {/* <Link to={`/chatbots?subcat=${subcategoryData?.id}`}><Image src={subcategoryData?.CoverImageUrl[1] ? subcategoryData?.CoverImageUrl[1] : ""} /></Link> */}
                  </div>
                </Col>}
                {subcategoryData?.CoverImageUrl?.[2] && <Col lg={6} className="CategoriesBigBox-p-0">
                  <div className="CategoriesBigBox-img">
                    <Link to={`/searchSubcategory?subcat=${subcategoryData?.SubcategoryId}`} state={subcategoryData?.Name}>
                      <Image src={JSON.parse(subcategoryData?.CoverImageUrl)[2]} />
                    </Link>
                    {/* <Link to={`/chatbots?subcat=${subcategoryData?.id}`}><Image src={subcategoryData?.CoverImageUrl[2]} /></Link> */}
                  </div>
                </Col>}
                {subcategoryData?.CoverImageUrl?.length > 3 && <Col lg={6} className="CategoriesBigBox-p-0">
                  <div className="CategoriesBigBox-sub-img-main">
                    <Row >
                      <Col lg={6}>
                        <div className="CategoriesBigBox-sub-Wrap">
                          <div className="CategoriesBigBox-sub-img">
                            <Link to={`/searchSubcategory?subcat=${subcategoryData?.SubcategoryId}`} state={subcategoryData?.Name}>
                              <Image src={JSON.parse(subcategoryData?.CoverImageUrl)[3]} />
                            </Link>
                            {/* <Link to={`/chatbots?subcat=${subcategoryData?.id}`}><Image src={subcategoryData?.CoverImageUrl[3]} /></Link> */}
                          </div>
                          <div className="CategoriesBigBox-sub-img">
                            <Link to={`/searchSubcategory?subcat=${subcategoryData?.SubcategoryId}`} state={subcategoryData?.Name}>
                              <Image src={JSON.parse(subcategoryData?.CoverImageUrl)[4]} />
                            </Link>
                            {/* <Link to={`/chatbots?subcat=${subcategoryData?.id}`}><Image src={subcategoryData?.CoverImageUrl[4]} /></Link> */}
                          </div>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="CategoriesBigBox-sub-Wrap">
                          {subcategoryData?.CoverImageUrl?.[5] && <div className="CategoriesBigBox-sub-img">
                            <Link to={`/searchSubcategory?subcat=${subcategoryData?.SubcategoryId}`} state={subcategoryData?.Name}><Image src={JSON.parse(subcategoryData?.CoverImageUrl)[5]} /></Link>
                          </div>}
                          <div className="CategoriesBigBox-sub-img-add">
                            <Link to="javascript:void(0)"><Icon icon="mingcute:add-fill" /></Link>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>}
                {subcategoryData?.CoverImageUrl?.length <= 3 && <Col lg={6} className="CategoriesBigBox-p-0">
                  <div className="CategoriesBigBox-sub-img-add">
                    <Link to="javascript:void(0)"><Icon icon="mingcute:add-fill" /></Link>
                  </div>
                </Col>}
              </Row>
            )}
          </div>
          <div className="CategoriesBigBox-Info">
            <Link to={`/searchSubcategory?subcat=${subcategoryData?.SubcategoryId}`} state={subcategoryData?.Name}>
              <h6>{subcategoryData?.Name}</h6>
            </Link>

          </div>
        </div>
      </Col>

    </>
  );
}

export default CategoriesBigSubcategories;
