import { useEffect, useRef } from 'react';
import useThrottle from "./hooks/useThrottle";

interface Props {
  code: string;
  device: "mobile" | "desktop";
}

function Preview({ code, device }: Props) {
  const throttledCode = useThrottle(code, 200);
  const iframeRef = useRef<HTMLIFrameElement | null>(null);

  useEffect(() => {
    const iframe = iframeRef.current;
    if (iframe && iframe.contentDocument) {
      iframe.contentDocument.open();
      iframe.contentDocument.write(throttledCode);
      iframe.contentDocument.close();
    }
  }, [throttledCode]);



  return (
      <iframe
        id={`preview-${device}`}
        ref={iframeRef}
        title="Preview"
        sandbox="allow-scripts allow-same-origin"
        style={{
          width: device === "desktop" ? '100%' : '375px',
          height: '90vh',
          border: '2px solid rgb(105 200 176)',
          borderRadius: '20px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          transform: 'scale(0.9)',
          transformOrigin: 'top',
          marginTop: '25px',
        }}
      />
  );
}

export default Preview;
