// ImageUpload.tsx
import React from 'react';
import { toast } from 'react-hot-toast';
import { Icon } from '@iconify/react';

interface ImageUploadProps {
  setReferenceImage: (file: File, dataUrl: string) => void;
}

const ImageUpload: React.FC<ImageUploadProps> = ({ setReferenceImage }) => {
  // Handler for file input change event
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files[0]) {
      const file = files[0];

      // Only proceed if the file is an image
      if (file.type.startsWith('image/')) {
        const dataUrl = URL.createObjectURL(file);
        setReferenceImage(file, dataUrl);
      } else {
        toast.error('Please select an image file.');
      }
    }
  };

  return (
    <>
      <input
        id="image-upload"
        type="file"
        accept="image/png, image/jpeg"
        onChange={handleFileChange}
        style={{ display: 'none' }}
      />
      <label htmlFor="image-upload" className='Chat-msg-imgUpload'>
        <Icon icon="ph:image" />
      </label>
      <div className="hovertext">
        <h4>Upload photos and files</h4>
        <p>you can upload any kind of images, and ask about it, or generate similar ones. </p>
      </div>
    </>
  );
};

export default ImageUpload;
